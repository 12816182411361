.alert {
  padding: 15px;
}

tbody {
  border-bottom: 1px solid #ddd;
}

.pagination li a,
.pagination li span {
  line-height: 1.1;
}

tbody tr {
  &.checked {
    background: #fcf8e3 !important;
    border: 1px solid #faebcc;

    &.new {
      background: #e0f0ff !important;
      border: 1px solid #a6d4ff;
    }

    td {
      border: none !important;
    }

    +tr td {
      border-top: none !important;
    }
  }

  &:last-child td {
    border-bottom: 1px solid #ddd;
  }
}

#product-edit {
  .table {
    margin: 0 -15px 25px;
    width: calc(100% + 40px) !important;

    tr th:first-child,
    tr td:first-child {
      padding-left: 15px;
    }

    tr th:last-child,
    tr td:last-child {
      padding-right: 15px;
    }
  }

  .box {
    .box-title {
      background: #fff;
      font-size: 26px;
      padding: 5px 20px;
      position: absolute;
      top: -41px;
      left: 10px;
      font-weight: normal;
    }
  }

  .bootstrap-select.btn-group.disabled,
  .bootstrap-select.btn-group>.disabled {
    background: #eee;

    &:hover {
      color: #000;
    }
  }

  select option:disabled {
    background-color: #ccc;
  }

  .input-sm {
    height: 30px;
  }

  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
  }

  .form-control {
    font-size: 18px;
    height: 34px;
    color: #5e5e5e;
  }

  textarea.form-control {
    height: auto;
  }

  .col-md-6:first-child {
    padding: 0 0 0 15px;
  }

  h5 {
    margin-bottom: 10px;
  }

  #save {
    margin-top: 20px;
    text-align: center;

    button {
      &#reset-bt {
        min-width: 70px;
      }

      &#save-bt {
        min-width: 100px;
      }
    }
  }

  .td-barcode {
    text-align: center;

    div {
      height: 0;
      overflow: visible;

      svg {
        position: relative;
        top: -4px;
        left: -5px
      }
    }
  }
}

/* css sidebar form https://bootsnipp.com/snippets/featured/sexy-sidebar-navigation */

.nav-sidebar {
  width: auto;
  margin: 8px 0 8px 0;
  padding-right: 15px;
  border-right: 1px solid #ddd;
  transition: all 0.2s ease;

  a {
    color: #333;
    -webkit-transition: all 0.08s linear;
    -moz-transition: all 0.08s linear;
    -o-transition: all 0.08s linear;
    transition: all 0.08s linear;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  .active a {
    cursor: default;
    background-color: #428bca !important;
    color: #fff;
    text-shadow: 1px 1px 1px #666;

    &:hover {
      background-color: #428bca;
    }
  }

  .changed a {
    color: #f00;
    background-color: #ffe0e0;
  }

  .text-overflow {

    a,
    .media-body {
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
  }

  &.pull-right {
    border-right: 0;
    border-left: 1px solid #ddd;

    a {
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }
  }

  &.sticky {
    position: fixed;
    top: 100px;
    left: 0;
    background: #fff;
    padding-top: 0px;
    margin: auto;
    width: 300px;
    /*height: calc(100vh - 200px);*/
    border-right: 1px solid #ddd;
  }
}

/* Right-aligned sidebar */

/* Sidebar sticky on scroll */

.tab-page-group .tab-page {
  display: none;
}

.images-list {
  margin-top: 20px;
}

.images-list .card {
  margin-bottom: 10px;
  position: relative;
}

.images-list .card.checked {
  background: #fcf8e3 !important;
}

.images-list .card .inner {
  width: 300px;
  height: 300px;
  line-height: 300px;
  text-align: center;
  background-color: #eee;
}

.images-list .card .bt {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, .8);
  width: 25px;
  height: 25px;
  line-height: 30px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

.images-list .card:hover .bt {
  display: block;
}

.images-list .card .delete-bt {
  top: 10px;
  right: 10px;
  color: red;
}

.images-list .card .up-bt {
  top: 138px;
  left: 10px;
}

.images-list .card .down-bt {
  top: 138px;
  right: 10px;
}

.for-print {
  &__grid {
    display: grid;
    grid-template-columns: 675px 298px;
    grid-column-gap: 14px;
  }

  &__card {
    border: 2px solid #007BFF;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Kanit', sans-serif;
    font-size: 16px;
    line-height: 1.5;

    &--table {
      padding: 0px 5px;
    }

    small {
      font-size: 12px;
    }

    .form {
      &-group {
        margin-bottom: 0;

        >label {
          white-space: nowrap;
          font-weight: 400;
        }

        &-after {
          margin-left: 5px;
        }

        &--inline {
          display: flex;
          align-items: center;

          >label {
            margin-right: 5px;
            margin-bottom: 0;
          }

          +.form-group--inline {
            margin-left: 10px;
          }
        }

        &--between {
          justify-content: space-between;
        }

        &--grow {
          flex-grow: 1;
        }

        &--more-info {
          margin-bottom: 0;
          height: 100%;
          display: flex;
          flex-direction: column;

          .form-control {
            border-width: 0;
            padding-left: 0;
            padding-right: 0;
            height: 100% !important;
            resize: none;
            box-shadow: none;

            &:focus {
              border-width: 0;
              box-shadow: none;
            }
          }
        }
      }

      &-control {
        padding-left: 6px;
        padding-right: 6px;
        font-size: 16px !important;
        font-weight: 400 !important;

        &[style^="--width"] {
          width: var(--width);
        }

        &--static {
          border-width: 0;
          padding: 0;
          box-shadow: none;
          pointer-events: none;
          height: auto !important;
        }
      }
    }

    .checkbox {
      &-group {
        .checkbox-inline+.checkbox-inline {
          margin-left: 20px;
        }
      }

      &-inline {
        input[type="checkbox"] {
          margin-top: 7px;
        }
      }
    }
    textarea{
      &.no-resize{
        resize: none !important;
      }
      &.is-overflow{
        border-color: red !important;
        &:focus{
          border-color: red !important;
          box-shadow: 0 0 0 .2rem rgba(red,.25);
        }
      }
    }
  }

  &__table {
    width: 100%;

    tr {
      &:last-child {
        td {
          border-bottom-color: transparent;
        }
      }

      th,
      td {
        padding: 10px 5px;
      }
    }

    thead tr th {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 5px;
        right: 5px;
        bottom: 0;
        border-bottom: 1px solid #000;
      }
    }

    &-header {
      min-height: 97px;
    }
  }

  &__actions {
    grid-column: span 2;
    text-align: right !important;
    margin-top: 16px !important;

    .btn+.btn {
      margin-left: 13px;
    }
  }

  &__notes{
    grid-template-columns: auto 1fr;
    margin-top: 1rem;
    color: red;
    &.show{
      display: grid !important;
    }
  }
}