.d-flex {
    display: flex !important;
    grid-column-gap: var(--col-gap);
}

.align-items {
    &-start {
        align-items: flex-start !important;
    }

    &-center {
        align-items: center !important;
    }

    &-end {
        align-items: flex-end !important;
    }
}

.justify {
    &-content {
        &-between {
            justify-content: space-between !important;
        }
    }
}