textarea.disable-resize {
	resize: none;
}

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
	color: #ababab;
}

.form {
	&-grid {
		display: grid;
		grid-template-columns: repeat(var(--column), 1fr);
		grid-column-gap: var(--gap-x);
		grid-row-gap: var(--gap-y);

		&--x {
			>div+div {
				position: relative;

				&::before {
					content: 'x';
					position: absolute;
					top: 50%;
					left: -25px;
					transform: translateY(-50%);
				}
			}
		}
	}

	&-row {
		display: flex;
		white-space: nowrap;

		&--center {
			align-items: center;

			>label {
				margin-bottom: 0;
			}
		}

		&--between {
			justify-content: space-between;
		}

		&--fluid {
			width: 100%;
		}
	}

	&-control {
		&--sm {
			font-size: 12px !important;
			height: 27px;
		}
	}
}

.selectpicker {
	&-fluid {
		.btn-group.bootstrap-select {
			width: 100% !important;

			>.dropdown-toggle {
				padding-left: 6px;
				padding-right: 6px;
			}
		}
	}
}

.checkbox {
	&-group {
		.checkbox-inline+.checkbox-inline {
			margin-left: 20px;
		}
	}

	&-grid {
		label+input[type="checkbox"] {
			margin-left: 8px;
		}
	}
}