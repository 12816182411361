.modal-dialog {
	&.modal-full {
		margin: 0;
		width: 100%;
		min-height: 100%;

		.modal-content {
			position: absolute;
			width: 100%;
			min-height: 100%;

			.close {
				font-size: 40px;
			}
		}
	}
}

[aria-hidden="true"]>div>.modal {
	z-index: 1040;
}

.modal {
	&--setting-drug-label {
		font-family: 'Kanit', sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;

		small {
			font-size: 12px;
		}

		.modal-dialog {
			width: max-content;
			margin-left: auto;
			margin-right: auto;
		}

		.form-control {
			padding-left: 6px;
			padding-right: 6px;
		}
	}
}