#page-error {
	color: #b5b4b4;
	margin: 0 auto;
	text-align: center;
	max-width: 1200px;

	.error-code {
		font-size:190px;

		img {
			width: 140px;
			margin: 0 -48px 1px -48px;
		}
	}

	.error-text {
		margin-top: 80px;

		.error-header {
			font-size: 36px;
			padding: 10px 0;
			margin: 10px 0;
		}

		.error-detail,
		.error-detail a {
			font-size: 20px;
			text-decoration: none;
		}

		.error-detail a {
			border-right: 1px solid #888;
			padding: 5px 10px;
			border-color: #EEEEEE;

			&:last-child { border-right: 0; }
		}
	}
}

@media( max-width: 768px ) {
	#page-error {
		.error-text {
			margin-top: 0px;
		}
	}
}