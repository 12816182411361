.gr-editor-title {
	color: #0471CE;
	border-bottom: 2px solid #0471CE;
	padding: 20px 0;
}
.gr-editor-list {
	list-style: none;
	padding: 0;

	.gr-editor-item {
		background: #fff;
		border-left: 4px solid $color-grey;
		box-shadow: 0 0 5px 0 #888;
		color: #333;
		display: block;
		margin: 20px auto 0;
		padding: 20px;
		transition: 1s;

		.title { padding: 10px; }

		&:hover { text-decoration: none; }
		&.disable { background: #d0d0d0; }
		&.disable:hover { cursor : not-allowed; }
		&.PRE_PROGRESS,
		&.warning { border-left-color: $color-orange; }
		&.IN_PROGRESS,
		&.ready { border-left-color: $color-yellow; }
		&.COMPLETED,
		&.success { border-left-color: $color-green; }
		&.CANCELED,
		&.danger { border-left-color: $color-red; }

		&:last-child { margin-bottom: 20px; }

		&.highlight {
			box-shadow: 0 0 5px 0 $color-orange;
		}
	}
}

.btn-to-top {
	position: fixed;
	bottom: 90px;
	right: 20px;
	width: 60px;
	height: 60px;
	background: #0071b2;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .06), 0 2px 32px 0 rgba(0, 0, 0, .16);
	z-index: 9999;
	text-align: center;
	transition: all ease 250ms;
	i {
		color: #fff;
		font-size: 30px;
		line-height: 56px;
	}
}
