$menu-blue-bg : #0081c0;
$menu-blue-hover-bg : #006eab;
$menu-green-bg : #01a9a9;
$menu-green-hover-bg : #018a8a;

/* Top Menu */
.top-menu {
	background-color: $menu-blue-bg;
	color: #fff;
	font-size: 20px;
	margin: 0 0 44px 0;
	width: 100%; min-height: 50px;
}
.menu-logo { float: left; margin: 13px 10px 10px 20px }
.menu-logo img { margin-top: -10px; margin-bottom: -10px; }

.menu-title { float: left; margin: 0; margin-right:15px; }
.menu-title > a { display: inline-block; color: #fff; padding: 15px 5px; }
.menu-title > a:hover { text-decoration: none; }
.menu-title > a:nth-child(2) { margin-left: -5px; }

.menu-hamburger { display: none; cursor: pointer; float: right; font-size: 20px; position: relative; top: 5px; right: 8px; z-index: $last-zindex; }
.menu-hamburger > span { position: absolute; top: 5px; right: 5px; }
.menu-hamburger .menu-close { display: none; }

.menu-left { float: left; }
.menu-right { float: right; }

.menu { margin-bottom: 0; padding: 0; }
.menu li { float: left; list-style: none; }
.menu li a,
.menu li a:visited { display: block; color: #fff; padding: 15px 15px; vertical-align: middle; text-decoration: none; }
.menu li a:hover,
.menu li.active a { background-color: $menu-blue-hover-bg; color: #fff; text-decoration: none; }

// Second Layer.
.menu-second-layer { display: none; background-color: $menu-blue-hover-bg; position: absolute; left: 0; right: 0; padding: 0; }
.menu-second-layer li { float: left; }
.menu-second-layer li a:hover,
.menu-second-layer li.active a { background-color: $body-bg-color; color:#000; }
.menu .open .menu-second-layer { display: block;}
.menu .open .menu-second-layer li a { padding: 6px 15px; }
.menu .open { background-color: $menu-blue-hover-bg; }

// Dropdown.
.menu-dropdown-inside:after { // Arrow.
	content: ' ';
	border-left 	: 10px solid transparent;
	border-right 	: 10px solid transparent;
	border-bottom : 10px solid $menu-blue-bg;
	position: absolute;
		right: 18px;
		top: -10px;
	width: 0; height: 0;
}
.menu-dropdown-inside {
	display: none;
	background-color: $menu-blue-bg;
	border-radius: 5px;
	padding: 0;
	position: absolute;
		top: 70px;
		right: 10px;
		left: inherit;
	min-width: 150px; max-width: 250px;
	z-index: $last-zindex - 1;
}
.menu-dropdown-inside li { display: block; width: 100%; }
.menu-dropdown-inside > li.active > a { background-color: $body-bg-color; color:#000; }
.menu-dropdown-inside > li > a:hover { color: white; background-color: $menu-blue-hover-bg; }

.menu-dropdown-inside li.divider {
	border: 1px solid darken($menu-blue-bg, 5%);
	margin: 0 5%;
	width: 90%;
}

// Multi lang
.menu-multi-lang {
	width: 52px;
}
.menu-multi-lang > a > img {
	position: relative;
	vertical-align: top;
	padding-top: 2px;
}
.menu-multi-lang .menu-dropdown-inside {
	right: 210px;
	min-width: 70px;
}
.menu-multi-lang .menu-dropdown-inside li a img {
	margin-right: 10px;
	width: 50px;
}

// Set new color for branch menu.
.top-menu.branch-menu { background-color: $menu-green-bg; }

.branch-menu .menu li a:hover,
.branch-menu .menu li.active a { background-color: $menu-green-hover-bg; }

.branch-menu .menu-second-layer { background-color: $menu-green-hover-bg; }
.branch-menu .menu-second-layer li a:hover,
.branch-menu .menu-second-layer li.active a { background-color: $body-bg-color; }

.branch-menu .menu .open { background-color: $menu-green-hover-bg; }

.branch-menu .menu-dropdown-inside { background-color: $menu-green-bg; }
.branch-menu .menu-dropdown-inside > li > a:hover { background-color: $menu-green-hover-bg; }
.branch-menu .menu-dropdown-inside li.divider { border: 1px solid darken($menu-green-bg, 5%); }

@media (max-width: 1000px) {
	.menu-hamburger { display: block; }
	.menu-title { border-right: 0px; }
	.menu-content { background-color: #000; position: fixed; right: -60%; overflow: scroll; width: 60%; height: 100%; min-height: 100%; z-index: $last-zindex - 1; }

	.menu { border-bottom: 2px solid #fff; float: none; position: static; width: 100%; }
	.menu li { display: block; float: none; }
	.menu li a,
	.menu li a:visited { background-color: $menu-blue-bg; display: block; color: #fff; padding: 15px 15px; vertical-align: middle; text-decoration: none; width: 100%; }
	.menu li.active a,
	.menu li a:hover { background-color: $menu-blue-hover-bg; color: #fff; }
	.menu li a:hover > ul li a { background-color: $menu-blue-bg; }

	.menu-second-layer { display: block; position: static; width: 100%; }
	.menu-second-layer li { width: 100%; }
	.open .menu-second-layer li a,
	.menu-second-layer li a { background-color: $menu-blue-bg; padding: 15px 15px 15px 30px; }
	.menu-second-layer li.active a,
	.menu-second-layer li a:hover { background-color: $menu-blue-hover-bg; color: #fff; }
	.menu-dropdown { border: 0; }

	// Set new color for branch menu.
	.branch-menu .menu li a,
	.branch-menu .menu li a:visited { background-color: $menu-green-bg; }
	.branch-menu .menu li.active a,
	.branch-menu .menu li a:hover { background-color: $menu-green-hover-bg; }
	.branch-menu .menu li a:hover > ul li a { background-color: $menu-green-bg; }
	.branch-menu .menu-second-layer li a:hover { background-color: $menu-green-hover-bg; }
}
