.filter-detail {
  color: $color-dark-grey;
  ul > li {
    display: inline-block;
    padding: 0 20px 0 8px;
    position: relative;

    &:after { content: '|'; position: absolute; right: 0; }
    &:last-of-type:after { content: ''; }
  }
}