.row {
	margin-right: 0px;
	margin-left: 0px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
	padding: 0 0 0 15px;
}

.col-lg-padding {
	padding: 0 0 0 30px;
}

.col-lg-1:first-child,
.col-lg-10:first-child,
.col-lg-11:first-child,
.col-lg-12:first-child,
.col-lg-2:first-child,
.col-lg-3:first-child,
.col-lg-4:first-child,
.col-lg-5:first-child,
.col-lg-6:first-child,
.col-lg-7:first-child,
.col-lg-8:first-child,
.col-lg-9:first-child,
.col-md-1:first-child,
.col-md-10:first-child,
.col-md-11:first-child,
.col-md-12:first-child,
.col-md-2:first-child,
.col-md-3:first-child,
.col-md-4:first-child,
.col-md-5:first-child,
.col-md-6:first-child,
.col-md-7:first-child,
.col-md-8:first-child,
.col-md-9:first-child,
.col-sm-1:first-child,
.col-sm-10:first-child,
.col-sm-11:first-child,
.col-sm-12:first-child,
.col-sm-2:first-child,
.col-sm-3:first-child,
.col-sm-4:first-child,
.col-sm-5:first-child,
.col-sm-6:first-child,
.col-sm-7:first-child,
.col-sm-8:first-child,
.col-sm-9:first-child,
.col-xs-1:first-child,
.col-xs-10:first-child,
.col-xs-11:first-child,
.col-xs-12:first-child,
.col-xs-2:first-child,
.col-xs-3:first-child,
.col-xs-4:first-child,
.col-xs-5:first-child,
.col-xs-6:first-child,
.col-xs-7:first-child,
.col-xs-8:first-child,
.col-xs-9:first-child {
	padding-left: 0px;
}

.d-grid {
	display: grid !important;
	grid-template-columns: repeat(var(--grid-col), 1fr);
	grid-column-gap: var(--col-gap);
	grid-row-gap: var(--row-gap);
}