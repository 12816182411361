.react-autosuggest__container {
	position: relative;

	.react-autosuggest__suggestions-container {
		background: #fff;
		border: 1px solid #D0D7E0;
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
		list-style: none;
		padding: 0;
		position : absolute;
			top: 33px;
			left: 0;
		width: 100%;
		z-index: $last-zindex;

		.react-autosuggest__suggestion {
			padding: 5px 10px;
			border-bottom: 1px dotted #d9d9d9;
			-webkit-transition: .2s; transition: .2s;

			&:last-childe {
				border-bottom: 0px;
			}
		}
		.react-autosuggest__suggestion--focused {
			background: #6890D6;
			color: #fff;
		}
	}
}