@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap');
$last-zindex : 10000;

// COLOURS SET.
$body-bg-color : #f5f5f5;
$color-grey : #b8b8b8;
$color-dark-grey : #656565;
$color-red : #e35051;
$color-orange : #ff9000;
$color-yellow : #F8CA00;
$color-green : #61c161;
$color-blue : #57c0e8;
$color-dark-blue : #0081c9;

html,
body {
  background-color: $body-bg-color;
  height: 100%;
  min-height: 100%;
  font-family: 'thai_sans_literegular', sans-serif;
  font-size: 18px;

  // filter
  // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); /* IE */
  // -webkit-filter: grayscale(1);/* Chrome, Safari */
  // filter: grayscale(1);/* Firefox */
}

#container {
  min-height: 100%;
  padding-top: 110px;
  margin-top: -93px;
  margin-bottom: -40px;
  padding-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 5px 0px;
  margin: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

.section-title {
  border-bottom: 2px solid #57c0e8;
  margin-bottom: 20px;
  margin-top: 20px;
}

.messages-title {
  border-bottom: 2px solid #57c0e8;
  margin-bottom: 20px;
  color: #57c0e8;
  font-weight: 600;
}

a {
  -webkit-transition: .5s;
  transition: .5s;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clr {
  clear: both;
}

.error-title {
  display: block;
  color: #ababab;
  font-size: 60px;
  margin: 50px auto;
  text-align: center;
  width: 80%;
}

.tr-checkbox {
  cursor: pointer;
}

.equal .panel {
  position: relative;
}

.equal .panel .panel-body {
  margin-bottom: 50px;
}

.equal .panel .panel-footer {
  background-color: #fff;
  border: 0;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.box {
  border: 1px solid #eee;
  padding: 30px 20px 10px 20px;
  margin: auto 5px 30px 5px;
  position: relative;
}

.box.danger {
  border: 1px solid #f2dede;
}

.box.info {
  border: 1px solid #245269;
}

.box.primary {
  border: 1px solid #337ab7;
}

.box.success {
  border: 1px solid #2b542c;
}

.box.warning {
  border: 1px solid #8a6d3b;
}

.box .box-title {
  background: #fff;
  font-size: 20px;
  padding: 5px 20px;
  position: absolute;
  top: -35px;
  left: 10px;
}

/* Bootstrap Modifies */
.page-header {
  margin: 10px 0 20px 0;
}

#product dl.dl-horizontal dt,
#product dl.dl-horizontal dd {
  padding: 3px 0;
}

.list-group-lg-icon {
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
}

@media (max-width: 1000px) {

  /*body { overflow-x: hidden; }*/
  .header {
    position: fixed;
    top: 0px;
    z-index: 99999;
    width: 100%;
  }

  #container {
    margin-top: 20px;
  }
}

//work place
.dd-title:after {
  content: ":";
  position: absolute;
  right: 0;
  word-wrap: break-word;
}

.dd-detail {
  color: #5f5f5f;
  word-wrap: break-word;
}

.help-block {
  font-size: 16px;
}

.label-control {
  margin-left: 15px;
}

#address-section>.box-title {
  margin-left: 15px;
}


/* Bootstrap - Modifies */
.panel-primary>.panel-heading {
  background-color: #0171c3;
}

.badge.notify-number {
  background-color: #ff9200;
  font-size: 10px;
  margin: 0 0 0 10px;
  padding: 3px;
  position: absolute;
  z-index: 1;
}

.panel-heading {
  background-color: #0081c9;
}

.panel-heading h5 {
  font-weight: bold;
}

.panel-primary {
  border-color: #f1f1f1;
}


.panel-title {
  font-size: 20px;
}

.input-group.input-group-search .form-control {
  float: right;
  width: 60%;
}

#setting-page .input-group-addon {
  float: none;
}

.form-control {
  font-size: 18px;
}


.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

//title form
.box-title {
  font-weight: 600;
  margin-top: 15px;
}

label {
  font-weight: 500;
}

.form-control {
  font-size: 18px;
  color: #5e5e5e;
}

.input-group-addon {
  padding: 9px 15px;
}

.input-group .form-control {
  float: left;
}

.thumbnail {
  border: 1px dashed #d2d2d2;
}

.margin-right-col {
  margin-left: 15px;
}

/* awesome icon - Modifies */
.fa {
  font-size: medium;
}

footer {
  background-color: #292923;
  color: #fff;
  padding: 10px;
  width: 100%;
}

//purchase-order
.total-price {
  padding: 0px 25px 0px 25px;
  background-color: #f8f8f8;

  h4 {
    margin: 0;
  }
}

.price-border {
  margin-left: 30px;
  border-left: 1px solid #d2d2d2;

  .row {
    padding-right: 30px;
    border-right: 1px solid #d2d2d2;
  }
}

.price-title {
  float: left;
}

.price-tag {
  float: right;
}

.total-price-tag {
  font-size: 40px;
}

.col-footer {
  padding: 20px 25px;
}

//Prin-order
.print-head {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 10px;
}

.print-border {
  border-left: 1px solid #d2d2d2;
  margin-left: 30px;
  float: left;
}

.signature-print {
  width: 150px;
  text-align: center;
  padding: 10px 0 0 0;

  hr {
    margin: 10px 5px 5px 5px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}


.print-footer {
  margin-bottom: 20px;
}

table .logo-print img {
  width: 100px;
}

//member
.disclaimer-text {
  margin-top: 10px;
}

.search-error-text {
  color: #f82b25;
  margin-top: 10px;
}

.result-search-members {
  margin-top: 20px;
}

//view customer
.form-horizontal .control-label-customer {
  margin-bottom: 0;
}

//edit employees
.label-bottom {
  margin-top: 8px;
}

//product create
.category-product-checkbox {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding-bottom: 10px;
}

//stock
.modal-header {
  padding: 0px;
  border-bottom: 2px solid #57c0e8;
  margin: 15px 15px 0 15px;
}

.modal-title {
  color: #57c0e8;
}

// .modal-dialog {
// width: 30%;
// }

.stock-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #d2d2d2;
}

.icon-padding {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;

  a {
    text-decoration: none;
    color: #000;
  }

  a:visited {
    color: #5e5e5e;
  }
}

.media {
  padding: 5px 0;
  margin: 0;
}

.media:hover {
  padding: 5px 0;
  background-color: #f8f8f8;
  margin: 0;
}

.media-footer {
  border-top: 1px #eee solid;
}

.unit-temp {
  padding: 20px 0 10px;
  border-bottom: 1px solid #eee;
}

.unit-title {
  padding-bottom: 10px;
}

.col-padding-top {
  padding-top: 20px !important;
}

.padding-checkbox {
  padding-left: 5px;
}

.modal-texttitle {
  color: #fff;
}

.total-amount {
  font-size: 50px;
  font-weight: 700;
  margin: -15px 0;
}

.total-margin {
  margin: -30px 0;
}

.text-align {
  vertical-align: text-bottom;
}

.img-fluid{
  width: 100%;
}

.small-widget {
  padding: 10px 25px !important;
}

.btn-margin-bottom {
  margin-bottom: 5px;
}

.btn-relative {
  position: relative;

  .amount {
    position: absolute;
    top: -15px;
    background: #ff0600;
    border-radius: 30px;
    padding: 0 10px;
    right: -12px;
    outline: none;
  }

}

#arincare_shopping_cart,
#product-edit-unsave,
#lineman-consent-modal,
#lineman-stopjoin-modal {
  font-family: 'Kanit', sans-serif;
  font-size: 14px;

  .btn {
    font-size: 14px;
  }

  p {
    font-size: 15px;
  }
}

.bill {
  border-bottom: 2px solid #e0e0e0;
  font-family: thaisans_neueregular;
  font-size: 12px;
  padding-bottom: 20px;

  .title {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  .sub-title {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .bill-table {
    width: 100%;

    .last-col {
      padding-left: 10px;
      // width: 60px;
    }

    .border-top {
      border-top: 1px solid #808080;
    }
  }
}

select option:disabled {
  background-color: #ccc;
}

.feature_flag_wholesale_price {
  display: none;
}

.feature_flag_credit_term {
  display: none;
}

.bg-white {
  background-color: #fff;
}

.list-group-item {
  border: transparent;
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 100px;
  right: 25px;
  display:none;
}

// Sass for Components.
@import 'bootstrap-modify/index';
@import 'components/index';

// Sass for pages.
@import 'pages/error';
@import 'pages/login';
@import 'pages/goods-receipts';
@import 'pages/product';
@import 'pages/companies';