.panel {
	border-color: #e8e8e8;
}
.panel-body {
	background-color: #fff;
	padding: 20px 25px;

	.table {
		margin: -20px -25px 0;
		width: calc(100% + 50px) !important;
		max-width: none;
		tr th:first-child, tr td:first-child {
			padding-left: 30px;
		}
		tr th:last-child, tr td:last-child {
			padding-right: 30px;
		}
	}
	.pagination {
		margin-bottom: 0;
	}
}

.branch .panel-primary > .panel-heading {
	background-color: #01A9A9;
}