#article-login {
	margin: 0 auto;
	max-width: 500px;
	.image-logo-login {
		margin-bottom: 30px;
		width: 100%;
	}
}

.panel-login {
	border: 1px solid #f3f3f3;
	padding: 20px;

	#login-btn {
		padding: 10px 0;
		margin-bottom: 10px;
	}
}