@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

.text-status { font-weight: 900; }

.text-status-large {
	margin-left: 15px;
	font-size: 40px;
	font-weight: 900;
}
.text-status-PRE_PROGRESS {
	@extend .text-status;
	color: $color-orange;
}
.text-status-IN_PROGRESS,
.text-status-PAID {
	@extend .text-status;
	color: $color-yellow;
}
.text-status-COMPLETED,
.text-status-PAID_IN_FULL {
	@extend .text-status;
	color: $color-green;
}
.text-status-CANCELED,
.text-status-UNPAID {
	@extend .text-status;
	color: $color-red;
}
.text-status-CANCELED02 {
	color: #FF0000;
	font-family: 'Kanit', sans-serif;
	font-size: 18px;;
}
.text-status-INVOICE_ISSUED {
    color: #337ab7;
}