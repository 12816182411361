#marketplace .tw-pointer-events-none{
  pointer-events: none;
}

#marketplace .tw-invisible{
  visibility: hidden;
}

#marketplace .tw-fixed{
  position: fixed;
}

#marketplace .tw-absolute{
  position: absolute;
}

#marketplace .tw-relative{
  position: relative;
}

#marketplace .tw-sticky{
  position: sticky;
}

#marketplace .tw-inset-0{
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#marketplace .tw-top-0{
  top: 0px;
}

#marketplace .tw-top-2{
  top: 0.5rem;
}

#marketplace .tw-top-3{
  top: 0.75rem;
}

#marketplace .tw-top-2\.5{
  top: 0.625rem;
}

#marketplace .tw-right-0{
  right: 0px;
}

#marketplace .tw-right-2{
  right: 0.5rem;
}

#marketplace .tw-right-3{
  right: 0.75rem;
}

#marketplace .tw-right-2\.5{
  right: 0.625rem;
}

#marketplace .tw-bottom-0{
  bottom: 0px;
}

#marketplace .tw-bottom-px{
  bottom: 1px;
}

#marketplace .tw-left-0{
  left: 0px;
}

#marketplace .tw-z-10{
  z-index: 10;
}

#marketplace .tw-z-20{
  z-index: 20;
}

#marketplace .tw-col-span-4{
  grid-column: span 4 / span 4;
}

#marketplace .tw-col-span-8{
  grid-column: span 8 / span 8;
}

#marketplace .tw-m-0{
  margin: 0px;
}

#marketplace .tw-mx-0{
  margin-left: 0px;
  margin-right: 0px;
}

#marketplace .tw-mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

#marketplace .tw-mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

#marketplace .tw-mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}

#marketplace .tw-mx-auto{
  margin-left: auto;
  margin-right: auto;
}

#marketplace .tw-mx-0\.5{
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

#marketplace .tw-my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

#marketplace .tw-my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#marketplace .tw-my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#marketplace .tw-my-5{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

#marketplace .tw-my-auto{
  margin-top: auto;
  margin-bottom: auto;
}

#marketplace .tw-my-2\.5{
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

#marketplace .tw-mt-1{
  margin-top: 0.25rem;
}

#marketplace .tw-mt-2{
  margin-top: 0.5rem;
}

#marketplace .tw-mt-3{
  margin-top: 0.75rem;
}

#marketplace .tw-mt-5{
  margin-top: 1.25rem;
}

#marketplace .tw-mt-10{
  margin-top: 2.5rem;
}

#marketplace .tw-mt-16{
  margin-top: 4rem;
}

#marketplace .tw-mt-20{
  margin-top: 5rem;
}

#marketplace .tw-mt-2\.5{
  margin-top: 0.625rem;
}

#marketplace .tw-mr-1{
  margin-right: 0.25rem;
}

#marketplace .tw-mr-2{
  margin-right: 0.5rem;
}

#marketplace .tw-mr-4{
  margin-right: 1rem;
}

#marketplace .tw-mr-1\.5{
  margin-right: 0.375rem;
}

#marketplace .tw-mb-1{
  margin-bottom: 0.25rem;
}

#marketplace .tw-mb-2{
  margin-bottom: 0.5rem;
}

#marketplace .tw-mb-3{
  margin-bottom: 0.75rem;
}

#marketplace .tw-mb-4{
  margin-bottom: 1rem;
}

#marketplace .tw-mb-5{
  margin-bottom: 1.25rem;
}

#marketplace .tw-mb-7{
  margin-bottom: 1.75rem;
}

#marketplace .tw-mb-10{
  margin-bottom: 2.5rem;
}

#marketplace .tw-mb-12{
  margin-bottom: 3rem;
}

#marketplace .tw-mb-16{
  margin-bottom: 4rem;
}

#marketplace .tw-mb-2\.5{
  margin-bottom: 0.625rem;
}

#marketplace .tw-mb-3\.5{
  margin-bottom: 0.875rem;
}

#marketplace .tw-ml-1{
  margin-left: 0.25rem;
}

#marketplace .tw-ml-2{
  margin-left: 0.5rem;
}

#marketplace .tw-ml-4{
  margin-left: 1rem;
}

#marketplace .tw-ml-8{
  margin-left: 2rem;
}

#marketplace .tw-ml-1\.5{
  margin-left: 0.375rem;
}

#marketplace .tw-ml-2\.5{
  margin-left: 0.625rem;
}

#marketplace .tw--ml-5{
  margin-left: -1.25rem;
}

#marketplace .tw-block{
  display: block;
}

#marketplace .tw-inline-block{
  display: inline-block;
}

#marketplace .tw-flex{
  display: flex;
}

#marketplace .tw-inline-flex{
  display: inline-flex;
}

#marketplace .tw-grid{
  display: grid;
}

#marketplace .tw-hidden{
  display: none;
}

#marketplace .tw-h-0{
  height: 0px;
}

#marketplace .tw-h-1{
  height: 0.25rem;
}

#marketplace .tw-h-3{
  height: 0.75rem;
}

#marketplace .tw-h-4{
  height: 1rem;
}

#marketplace .tw-h-5{
  height: 1.25rem;
}

#marketplace .tw-h-6{
  height: 1.5rem;
}

#marketplace .tw-h-8{
  height: 2rem;
}

#marketplace .tw-h-9{
  height: 2.25rem;
}

#marketplace .tw-h-10{
  height: 2.5rem;
}

#marketplace .tw-h-12{
  height: 3rem;
}

#marketplace .tw-h-14{
  height: 3.5rem;
}

#marketplace .tw-h-16{
  height: 4rem;
}

#marketplace .tw-h-24{
  height: 6rem;
}

#marketplace .tw-h-36{
  height: 9rem;
}

#marketplace .tw-h-40{
  height: 10rem;
}

#marketplace .tw-h-80{
  height: 20rem;
}

#marketplace .tw-h-96{
  height: 24rem;
}

#marketplace .tw-h-auto{
  height: auto;
}

#marketplace .tw-h-3\.5{
  height: 0.875rem;
}

#marketplace .tw-h-full{
  height: 100%;
}

#marketplace .tw-h-screen{
  height: 100vh;
}

#marketplace .tw-w-2{
  width: 0.5rem;
}

#marketplace .tw-w-3{
  width: 0.75rem;
}

#marketplace .tw-w-4{
  width: 1rem;
}

#marketplace .tw-w-5{
  width: 1.25rem;
}

#marketplace .tw-w-6{
  width: 1.5rem;
}

#marketplace .tw-w-8{
  width: 2rem;
}

#marketplace .tw-w-10{
  width: 2.5rem;
}

#marketplace .tw-w-12{
  width: 3rem;
}

#marketplace .tw-w-14{
  width: 3.5rem;
}

#marketplace .tw-w-16{
  width: 4rem;
}

#marketplace .tw-w-20{
  width: 5rem;
}

#marketplace .tw-w-24{
  width: 6rem;
}

#marketplace .tw-w-28{
  width: 7rem;
}

#marketplace .tw-w-32{
  width: 8rem;
}

#marketplace .tw-w-36{
  width: 9rem;
}

#marketplace .tw-w-40{
  width: 10rem;
}

#marketplace .tw-w-48{
  width: 12rem;
}

#marketplace .tw-w-52{
  width: 13rem;
}

#marketplace .tw-w-72{
  width: 18rem;
}

#marketplace .tw-w-96{
  width: 24rem;
}

#marketplace .tw-w-2\.5{
  width: 0.625rem;
}

#marketplace .tw-w-3\.5{
  width: 0.875rem;
}

#marketplace .tw-w-1\/2{
  width: 50%;
}

#marketplace .tw-w-5\/12{
  width: 41.666667%;
}

#marketplace .tw-w-6\/12{
  width: 50%;
}

#marketplace .tw-w-full{
  width: 100%;
}

#marketplace .tw-min-w-full{
  min-width: 100%;
}

#marketplace .tw-max-w-0{
  max-width: 0rem;
}

#marketplace .tw-max-w-screen-md{
  max-width: 768px;
}

#marketplace .tw-flex-1{
  flex: 1 1 0%;
}

#marketplace .tw-flex-auto{
  flex: 1 1 auto;
}

#marketplace .tw-flex-none{
  flex: none;
}

#marketplace .tw-flex-grow{
  flex-grow: 1;
}

#marketplace .tw-transform{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#marketplace .tw-rotate-180{
  --tw-rotate: 180deg;
}

@-webkit-keyframes tw-spin{
  to{
    transform: rotate(360deg);
  }
}

@keyframes tw-spin{
  to{
    transform: rotate(360deg);
  }
}

@-webkit-keyframes tw-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes tw-ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes tw-pulse{
  50%{
    opacity: .5;
  }
}

@keyframes tw-pulse{
  50%{
    opacity: .5;
  }
}

@-webkit-keyframes tw-bounce{
  0%, 100%{
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes tw-bounce{
  0%, 100%{
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

#marketplace .tw-animate-spin{
  -webkit-animation: tw-spin 1s linear infinite;
          animation: tw-spin 1s linear infinite;
}

#marketplace .tw-cursor-pointer{
  cursor: pointer;
}

#marketplace .tw-cursor-not-allowed{
  cursor: not-allowed;
}

#marketplace .tw-select-none{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

#marketplace .tw-list-disc{
  list-style-type: disc;
}

#marketplace .tw-appearance-none{
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

#marketplace .tw-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

#marketplace .tw-grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

#marketplace .tw-flex-row{
  flex-direction: row;
}

#marketplace .tw-flex-col{
  flex-direction: column;
}

#marketplace .tw-content-center{
  align-content: center;
}

#marketplace .tw-items-start{
  align-items: flex-start;
}

#marketplace .tw-items-end{
  align-items: flex-end;
}

#marketplace .tw-items-center{
  align-items: center;
}

#marketplace .tw-justify-start{
  justify-content: flex-start;
}

#marketplace .tw-justify-end{
  justify-content: flex-end;
}

#marketplace .tw-justify-center{
  justify-content: center;
}

#marketplace .tw-justify-between{
  justify-content: space-between;
}

#marketplace .tw-gap-1{
  gap: 0.25rem;
}

#marketplace .tw-gap-2{
  gap: 0.5rem;
}

#marketplace .tw-gap-3{
  gap: 0.75rem;
}

#marketplace .tw-gap-6{
  gap: 1.5rem;
}

#marketplace .tw-gap-1\.5{
  gap: 0.375rem;
}

#marketplace .tw-gap-3\.5{
  gap: 0.875rem;
}

#marketplace .tw-gap-x-1{
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
}

#marketplace .tw-gap-x-2{
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

#marketplace .tw-gap-x-3{
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

#marketplace .tw-gap-x-4{
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

#marketplace .tw-gap-x-6{
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}

#marketplace .tw-gap-x-16{
  -moz-column-gap: 4rem;
       column-gap: 4rem;
}

#marketplace .tw-gap-x-1\.5{
  -moz-column-gap: 0.375rem;
       column-gap: 0.375rem;
}

#marketplace .tw-gap-x-3\.5{
  -moz-column-gap: 0.875rem;
       column-gap: 0.875rem;
}

#marketplace .tw-gap-y-1{
  row-gap: 0.25rem;
}

#marketplace .tw-gap-y-2{
  row-gap: 0.5rem;
}

#marketplace .tw-gap-y-3{
  row-gap: 0.75rem;
}

#marketplace .tw-gap-y-10{
  row-gap: 2.5rem;
}

#marketplace .tw-gap-y-1\.5{
  row-gap: 0.375rem;
}

#marketplace .tw-gap-y-2\.5{
  row-gap: 0.625rem;
}

#marketplace .tw-gap-y-3\.5{
  row-gap: 0.875rem;
}

#marketplace .tw-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

#marketplace .tw-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

#marketplace .tw-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

#marketplace .tw-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}

#marketplace .tw-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

#marketplace .tw-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

#marketplace .tw-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

#marketplace .tw-divide-x > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

#marketplace .tw-divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

#marketplace .tw-divide-cta-gray > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(205, 205, 205, var(--tw-divide-opacity));
}

#marketplace .tw-divide-primary_gray > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1;
  border-color: rgba(155, 155, 155, var(--tw-divide-opacity));
}

#marketplace .tw-overflow-hidden{
  overflow: hidden;
}

#marketplace .tw-overflow-y-auto{
  overflow-y: auto;
}

#marketplace .tw-overflow-x-hidden{
  overflow-x: hidden;
}

#marketplace .tw-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#marketplace .tw-whitespace-nowrap{
  white-space: nowrap;
}

#marketplace .tw-whitespace-pre{
  white-space: pre;
}

#marketplace .tw-break-words{
  overflow-wrap: break-word;
}

#marketplace .tw-break-all{
  word-break: break-all;
}

#marketplace .tw-rounded-none{
  border-radius: 0px;
}

#marketplace .tw-rounded{
  border-radius: 0.25rem;
}

#marketplace .tw-rounded-md{
  border-radius: 0.375rem;
}

#marketplace .tw-rounded-lg{
  border-radius: 0.5rem;
}

#marketplace .tw-rounded-2xl{
  border-radius: 1rem;
}

#marketplace .tw-rounded-full{
  border-radius: 9999px;
}

#marketplace .tw-rounded-t{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

#marketplace .tw-rounded-t-md{
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

#marketplace .tw-rounded-r{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#marketplace .tw-rounded-b{
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

#marketplace .tw-rounded-l{
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

#marketplace .tw-rounded-tr{
  border-top-right-radius: 0.25rem;
}

#marketplace .tw-border-0{
  border-width: 0px;
}

#marketplace .tw-border{
  border-width: 1px;
}

#marketplace .tw-border-t-4{
  border-top-width: 4px;
}

#marketplace .tw-border-t{
  border-top-width: 1px;
}

#marketplace .tw-border-r{
  border-right-width: 1px;
}

#marketplace .tw-border-b-2{
  border-bottom-width: 2px;
}

#marketplace .tw-border-b{
  border-bottom-width: 1px;
}

#marketplace .tw-border-l{
  border-left-width: 1px;
}

#marketplace .last\:tw-border-b-0:last-child{
  border-bottom-width: 0px;
}

#marketplace .tw-border-solid{
  border-style: solid;
}

#marketplace .tw-border-dashed{
  border-style: dashed;
}

#marketplace .tw-border-event-orange{
  --tw-border-opacity: 1;
  border-color: rgba(255, 136, 50, var(--tw-border-opacity));
}

#marketplace .tw-border-event-red{
  --tw-border-opacity: 1;
  border-color: rgba(255, 0, 0, var(--tw-border-opacity));
}

#marketplace .tw-border-event-green{
  --tw-border-opacity: 1;
  border-color: rgba(0, 192, 54, var(--tw-border-opacity));
}

#marketplace .tw-border-cta-primary-orange{
  --tw-border-opacity: 1;
  border-color: rgba(255, 95, 45, var(--tw-border-opacity));
}

#marketplace .tw-border-cta-secondary_blue{
  --tw-border-opacity: 1;
  border-color: rgba(0, 123, 255, var(--tw-border-opacity));
}

#marketplace .tw-border-cta-gray{
  --tw-border-opacity: 1;
  border-color: rgba(205, 205, 205, var(--tw-border-opacity));
}

#marketplace .tw-border-black{
  --tw-border-opacity: 1;
  border-color: rgba(73, 73, 73, var(--tw-border-opacity));
}

#marketplace .tw-border-primary_gray{
  --tw-border-opacity: 1;
  border-color: rgba(155, 155, 155, var(--tw-border-opacity));
}

#marketplace .tw-border-third_gray{
  --tw-border-opacity: 1;
  border-color: rgba(205, 205, 205, var(--tw-border-opacity));
}

#marketplace .tw-border-secondary_green{
  --tw-border-opacity: 1;
  border-color: rgba(91, 221, 156, var(--tw-border-opacity));
}

#marketplace .tw-border-gray{
  --tw-border-opacity: 1;
  border-color: rgba(237, 237, 237, var(--tw-border-opacity));
}

#marketplace .tw-bg-gold{
  --tw-bg-opacity: 1;
  background-color: rgba(196, 136, 13, var(--tw-bg-opacity));
}

#marketplace .tw-bg-event-red{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

#marketplace .tw-bg-event-green{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 192, 54, var(--tw-bg-opacity));
}

#marketplace .tw-bg-event-success{
  --tw-bg-opacity: 1;
  background-color: rgba(194, 255, 211, var(--tw-bg-opacity));
}

#marketplace .tw-bg-event-search_warning_load{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 171, 70, var(--tw-bg-opacity));
}

#marketplace .tw-bg-cta-primary-orange{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 95, 45, var(--tw-bg-opacity));
}

#marketplace .tw-bg-cta-primary_blue{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 192, var(--tw-bg-opacity));
}

#marketplace .tw-bg-cta-secondary_blue{
  --tw-bg-opacity: 1;
  background-color: rgba(0, 123, 255, var(--tw-bg-opacity));
}

#marketplace .tw-bg-cta-gray{
  --tw-bg-opacity: 1;
  background-color: rgba(205, 205, 205, var(--tw-bg-opacity));
}

#marketplace .tw-bg-alert-success{
  --tw-bg-opacity: 1;
  background-color: rgba(235, 251, 243, var(--tw-bg-opacity));
}

#marketplace .tw-bg-alert-warning{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 244, 230, var(--tw-bg-opacity));
}

#marketplace .tw-bg-alert-error{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 236, 235, var(--tw-bg-opacity));
}

#marketplace .tw-bg-alert-info{
  --tw-bg-opacity: 1;
  background-color: rgba(234, 244, 255, var(--tw-bg-opacity));
}

#marketplace .tw-bg-black{
  --tw-bg-opacity: 1;
  background-color: rgba(73, 73, 73, var(--tw-bg-opacity));
}

#marketplace .tw-bg-white{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

#marketplace .tw-bg-secondary_green{
  --tw-bg-opacity: 1;
  background-color: rgba(91, 221, 156, var(--tw-bg-opacity));
}

#marketplace .tw-bg-gray{
  --tw-bg-opacity: 1;
  background-color: rgba(237, 237, 237, var(--tw-bg-opacity));
}

#marketplace .tw-bg-gray1{
  --tw-bg-opacity: 1;
  background-color: rgba(248, 249, 250, var(--tw-bg-opacity));
}

#marketplace .hover\:tw-bg-primary_red:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(183, 36, 36, var(--tw-bg-opacity));
}

#marketplace .hover\:tw-bg-event-search_warning_load:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(239, 171, 70, var(--tw-bg-opacity));
}

#marketplace .hover\:tw-bg-white:hover{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

#marketplace .tw-object-cover{
  -o-object-fit: cover;
     object-fit: cover;
}

#marketplace .tw-p-0{
  padding: 0px;
}

#marketplace .tw-p-1{
  padding: 0.25rem;
}

#marketplace .tw-p-2{
  padding: 0.5rem;
}

#marketplace .tw-p-3{
  padding: 0.75rem;
}

#marketplace .tw-p-4{
  padding: 1rem;
}

#marketplace .tw-p-5{
  padding: 1.25rem;
}

#marketplace .tw-p-0\.5{
  padding: 0.125rem;
}

#marketplace .tw-p-1\.5{
  padding: 0.375rem;
}

#marketplace .tw-p-2\.5{
  padding: 0.625rem;
}

#marketplace .tw-p-3\.5{
  padding: 0.875rem;
}

#marketplace .tw-px-0{
  padding-left: 0px;
  padding-right: 0px;
}

#marketplace .tw-px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

#marketplace .tw-px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

#marketplace .tw-px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

#marketplace .tw-px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

#marketplace .tw-px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

#marketplace .tw-px-7{
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

#marketplace .tw-px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

#marketplace .tw-px-11{
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

#marketplace .tw-px-2\.5{
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

#marketplace .tw-px-3\.5{
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

#marketplace .tw-py-0{
  padding-top: 0px;
  padding-bottom: 0px;
}

#marketplace .tw-py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

#marketplace .tw-py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#marketplace .tw-py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

#marketplace .tw-py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#marketplace .tw-py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

#marketplace .tw-py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#marketplace .tw-py-12{
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#marketplace .tw-py-32{
  padding-top: 8rem;
  padding-bottom: 8rem;
}

#marketplace .tw-py-0\.5{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

#marketplace .tw-py-1\.5{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

#marketplace .tw-py-2\.5{
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

#marketplace .tw-py-3\.5{
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

#marketplace .tw-pt-2{
  padding-top: 0.5rem;
}

#marketplace .tw-pt-4{
  padding-top: 1rem;
}

#marketplace .tw-pt-5{
  padding-top: 1.25rem;
}

#marketplace .tw-pt-2\.5{
  padding-top: 0.625rem;
}

#marketplace .tw-pr-1{
  padding-right: 0.25rem;
}

#marketplace .tw-pr-2{
  padding-right: 0.5rem;
}

#marketplace .tw-pr-3{
  padding-right: 0.75rem;
}

#marketplace .tw-pr-4{
  padding-right: 1rem;
}

#marketplace .tw-pr-5{
  padding-right: 1.25rem;
}

#marketplace .tw-pr-9{
  padding-right: 2.25rem;
}

#marketplace .tw-pr-3\.5{
  padding-right: 0.875rem;
}

#marketplace .tw-pb-1{
  padding-bottom: 0.25rem;
}

#marketplace .tw-pb-2{
  padding-bottom: 0.5rem;
}

#marketplace .tw-pb-4{
  padding-bottom: 1rem;
}

#marketplace .tw-pb-6{
  padding-bottom: 1.5rem;
}

#marketplace .tw-pb-2\.5{
  padding-bottom: 0.625rem;
}

#marketplace .tw-pl-2{
  padding-left: 0.5rem;
}

#marketplace .tw-pl-8{
  padding-left: 2rem;
}

#marketplace .tw-pl-10{
  padding-left: 2.5rem;
}

#marketplace .tw-pl-2\.5{
  padding-left: 0.625rem;
}

#marketplace .tw-text-left{
  text-align: left;
}

#marketplace .tw-text-center{
  text-align: center;
}

#marketplace .tw-text-right{
  text-align: right;
}

#marketplace .tw-align-middle{
  vertical-align: middle;
}

#marketplace .tw-font-serif{
  font-family: Kanit, Georgia, Cambria, "Times New Roman", Times, serif;
}

#marketplace .tw-text-headline_1{
  font-size: 48px;
  line-height: 40px;
}

#marketplace .tw-text-label{
  font-size: 36px;
  line-height: 40px;
}

#marketplace .tw-text-label_1{
  font-size: 24px;
  line-height: 25px;
}

#marketplace .tw-text-body{
  font-size: 18px;
  line-height: 28px;
}

#marketplace .tw-text-body_1{
  font-size: 16px;
  line-height: 26px;
}

#marketplace .tw-text-body_2{
  font-size: 14px;
  line-height: 24px;
}

#marketplace .tw-text-hint{
  font-size: 12px;
  line-height: 16px;
}

#marketplace .tw-font-normal{
  font-weight: 400;
}

#marketplace .tw-font-bold{
  font-weight: 700;
}

#marketplace .tw-uppercase{
  text-transform: uppercase;
}

#marketplace .tw-leading-5{
  line-height: 1.25rem;
}

#marketplace .tw-leading-6{
  line-height: 1.5rem;
}

#marketplace .tw-leading-tight{
  line-height: 1.25;
}

#marketplace .tw-leading-normal{
  line-height: 1.5;
}

#marketplace .tw-tracking-wider{
  letter-spacing: 0.05em;
}

#marketplace .tw-text-event-orange{
  --tw-text-opacity: 1;
  color: rgba(255, 136, 50, var(--tw-text-opacity));
}

#marketplace .tw-text-event-red{
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

#marketplace .tw-text-event-green{
  --tw-text-opacity: 1;
  color: rgba(0, 192, 54, var(--tw-text-opacity));
}

#marketplace .tw-text-event-search_warning_load{
  --tw-text-opacity: 1;
  color: rgba(239, 171, 70, var(--tw-text-opacity));
}

#marketplace .tw-text-cta-primary-orange{
  --tw-text-opacity: 1;
  color: rgba(255, 95, 45, var(--tw-text-opacity));
}

#marketplace .tw-text-cta-secondary_red{
  --tw-text-opacity: 1;
  color: rgba(231, 64, 12, var(--tw-text-opacity));
}

#marketplace .tw-text-cta-primary_blue{
  --tw-text-opacity: 1;
  color: rgba(0, 131, 192, var(--tw-text-opacity));
}

#marketplace .tw-text-cta-secondary_blue{
  --tw-text-opacity: 1;
  color: rgba(0, 123, 255, var(--tw-text-opacity));
}

#marketplace .tw-text-cta-gray{
  --tw-text-opacity: 1;
  color: rgba(205, 205, 205, var(--tw-text-opacity));
}

#marketplace .tw-text-black{
  --tw-text-opacity: 1;
  color: rgba(73, 73, 73, var(--tw-text-opacity));
}

#marketplace .tw-text-primary_gray{
  --tw-text-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-text-opacity));
}

#marketplace .tw-text-secondary_gray{
  --tw-text-opacity: 1;
  color: rgba(64, 64, 64, var(--tw-text-opacity));
}

#marketplace .tw-text-white{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

#marketplace .tw-text-red-error{
  --tw-text-opacity: 1;
  color: rgba(255, 42, 42, var(--tw-text-opacity));
}

#marketplace .tw-text-secondary_warning{
  --tw-text-opacity: 1;
  color: rgba(139, 110, 60, var(--tw-text-opacity));
}

#marketplace .tw-text-secondary_error{
  --tw-text-opacity: 1;
  color: rgba(200, 67, 67, var(--tw-text-opacity));
}

#marketplace .hover\:tw-text-cta-secondary_red:hover{
  --tw-text-opacity: 1;
  color: rgba(231, 64, 12, var(--tw-text-opacity));
}

#marketplace .hover\:tw-text-white:hover{
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

#marketplace .tw-underline{
  text-decoration: underline;
}

#marketplace .tw-no-underline{
  text-decoration: none;
}

#marketplace .tw-placeholder-primary_gray::-moz-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-placeholder-opacity));
}

#marketplace .tw-placeholder-primary_gray:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-placeholder-opacity));
}

#marketplace .tw-placeholder-primary_gray::placeholder{
  --tw-placeholder-opacity: 1;
  color: rgba(155, 155, 155, var(--tw-placeholder-opacity));
}

#marketplace .tw-opacity-20{
  opacity: 0.2;
}

#marketplace .tw-opacity-25{
  opacity: 0.25;
}

#marketplace .tw-opacity-50{
  opacity: 0.5;
}

#marketplace .tw-opacity-70{
  opacity: 0.7;
}

#marketplace .tw-opacity-75{
  opacity: 0.75;
}

#marketplace .hover\:tw-opacity-80:hover{
  opacity: 0.8;
}

#marketplace .disabled\:tw-opacity-50:disabled{
  opacity: 0.5;
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000;
}

#marketplace .tw-shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#marketplace .tw-shadow-md{
  --tw-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#marketplace .tw-shadow-none{
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#marketplace .focus\:tw-outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

#marketplace .focus\:tw-ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#marketplace .tw-filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#marketplace .tw-grayscale{
  --tw-grayscale: grayscale(100%);
}

#marketplace .tw-backdrop-filter{
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

#marketplace .tw-backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px);
}

#marketplace .tw-transition-all{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-break-all {
  word-break: break-all;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.tw-break-normal {
  word-break: normal;
}

.tw-break-words {
  word-break: break-word;
}

.gradient-red {
  background: linear-gradient(180deg, #DA0000 15.42%, #FF0000 100%);
}

.gradient-gold {
  background: linear-gradient(270deg, #BD8003 9.11%, #CD921A 46.57%, #FFE684 89.57%, #C68A10 228.78%);
}

@media (min-width: 640px){
  #marketplace .sm\:tw-mt-40{
    margin-top: 10rem;
  }

  #marketplace .sm\:tw-mb-8{
    margin-bottom: 2rem;
  }

  #marketplace .sm\:tw-gap-0{
    gap: 0px;
  }

  #marketplace .sm\:tw-px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  #marketplace .sm\:tw-text-body_2{
    font-size: 14px;
    line-height: 24px;
  }
}

@media (min-width: 768px){
  #marketplace .md\:tw-col-span-2{
    grid-column: span 2 / span 2;
  }

  #marketplace .md\:tw-col-span-10{
    grid-column: span 10 / span 10;
  }

  #marketplace .md\:tw-gap-4{
    gap: 1rem;
  }
}

@media (min-width: 1024px){
  #marketplace .lg\:tw-ml-8{
    margin-left: 2rem;
  }

  #marketplace .lg\:tw-w-20{
    width: 5rem;
  }

  #marketplace .lg\:tw-w-32{
    width: 8rem;
  }

  #marketplace .lg\:tw-w-36{
    width: 9rem;
  }

  #marketplace .lg\:tw-py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1280px){
  #marketplace .xl\:tw-mt-72{
    margin-top: 18rem;
  }

  #marketplace .xl\:tw-mb-8{
    margin-bottom: 2rem;
  }
}

@media (min-width: 1536px){
  #marketplace .\32xl\:tw-mt-8{
    margin-top: 2rem;
  }

  #marketplace .\32xl\:tw-mb-2{
    margin-bottom: 0.5rem;
  }
}