@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
html,
body {
  background-color: #f5f5f5;
  height: 100%;
  min-height: 100%;
  font-family: 'thai_sans_literegular', sans-serif;
  font-size: 18px;
}

#container {
  min-height: 100%;
  padding-top: 110px;
  margin-top: -93px;
  margin-bottom: -40px;
  padding-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 5px 0px;
  margin: 0;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

.section-title {
  border-bottom: 2px solid #57c0e8;
  margin-bottom: 20px;
  margin-top: 20px;
}

.messages-title {
  border-bottom: 2px solid #57c0e8;
  margin-bottom: 20px;
  color: #57c0e8;
  font-weight: 600;
}

a {
  -webkit-transition: .5s;
  transition: .5s;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clr {
  clear: both;
}

.error-title {
  display: block;
  color: #ababab;
  font-size: 60px;
  margin: 50px auto;
  text-align: center;
  width: 80%;
}

.tr-checkbox {
  cursor: pointer;
}

.equal .panel {
  position: relative;
}

.equal .panel .panel-body {
  margin-bottom: 50px;
}

.equal .panel .panel-footer {
  background-color: #fff;
  border: 0;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.box {
  border: 1px solid #eee;
  padding: 30px 20px 10px 20px;
  margin: auto 5px 30px 5px;
  position: relative;
}

.box.danger {
  border: 1px solid #f2dede;
}

.box.info {
  border: 1px solid #245269;
}

.box.primary {
  border: 1px solid #337ab7;
}

.box.success {
  border: 1px solid #2b542c;
}

.box.warning {
  border: 1px solid #8a6d3b;
}

.box .box-title {
  background: #fff;
  font-size: 20px;
  padding: 5px 20px;
  position: absolute;
  top: -35px;
  left: 10px;
}

/* Bootstrap Modifies */
.page-header {
  margin: 10px 0 20px 0;
}

#product dl.dl-horizontal dt,
#product dl.dl-horizontal dd {
  padding: 3px 0;
}

.list-group-lg-icon {
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -15px;
}

@media (max-width: 1000px) {
  /*body { overflow-x: hidden; }*/
  .header {
    position: fixed;
    top: 0px;
    z-index: 99999;
    width: 100%;
  }
  #container {
    margin-top: 20px;
  }
}

.dd-title:after {
  content: ":";
  position: absolute;
  right: 0;
  word-wrap: break-word;
}

.dd-detail {
  color: #5f5f5f;
  word-wrap: break-word;
}

.help-block {
  font-size: 16px;
}

.label-control {
  margin-left: 15px;
}

#address-section > .box-title {
  margin-left: 15px;
}

/* Bootstrap - Modifies */
.panel-primary > .panel-heading {
  background-color: #0171c3;
}

.badge.notify-number {
  background-color: #ff9200;
  font-size: 10px;
  margin: 0 0 0 10px;
  padding: 3px;
  position: absolute;
  z-index: 1;
}

.panel-heading {
  background-color: #0081c9;
}

.panel-heading h5 {
  font-weight: bold;
}

.panel-primary {
  border-color: #f1f1f1;
}

.panel-title {
  font-size: 20px;
}

.input-group.input-group-search .form-control {
  float: right;
  width: 60%;
}

#setting-page .input-group-addon {
  float: none;
}

.form-control {
  font-size: 18px;
}

.form-horizontal .form-group {
  margin-left: 0px;
  margin-right: 0px;
}

.box-title {
  font-weight: 600;
  margin-top: 15px;
}

label {
  font-weight: 500;
}

.form-control {
  font-size: 18px;
  color: #5e5e5e;
}

.input-group-addon {
  padding: 9px 15px;
}

.input-group .form-control {
  float: left;
}

.thumbnail {
  border: 1px dashed #d2d2d2;
}

.margin-right-col {
  margin-left: 15px;
}

/* awesome icon - Modifies */
.fa {
  font-size: medium;
}

footer {
  background-color: #292923;
  color: #fff;
  padding: 10px;
  width: 100%;
}

.total-price {
  padding: 0px 25px 0px 25px;
  background-color: #f8f8f8;
}

.total-price h4 {
  margin: 0;
}

.price-border {
  margin-left: 30px;
  border-left: 1px solid #d2d2d2;
}

.price-border .row {
  padding-right: 30px;
  border-right: 1px solid #d2d2d2;
}

.price-title {
  float: left;
}

.price-tag {
  float: right;
}

.total-price-tag {
  font-size: 40px;
}

.col-footer {
  padding: 20px 25px;
}

.print-head {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 10px;
}

.print-border {
  border-left: 1px solid #d2d2d2;
  margin-left: 30px;
  float: left;
}

.signature-print {
  width: 150px;
  text-align: center;
  padding: 10px 0 0 0;
}

.signature-print hr {
  margin: 10px 5px 5px 5px;
}

.signature-print p {
  font-size: 14px;
  margin: 0;
}

.print-footer {
  margin-bottom: 20px;
}

table .logo-print img {
  width: 100px;
}

.disclaimer-text {
  margin-top: 10px;
}

.search-error-text {
  color: #f82b25;
  margin-top: 10px;
}

.result-search-members {
  margin-top: 20px;
}

.form-horizontal .control-label-customer {
  margin-bottom: 0;
}

.label-bottom {
  margin-top: 8px;
}

.category-product-checkbox {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding-bottom: 10px;
}

.modal-header {
  padding: 0px;
  border-bottom: 2px solid #57c0e8;
  margin: 15px 15px 0 15px;
}

.modal-title {
  color: #57c0e8;
}

.stock-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #d2d2d2;
}

.icon-padding {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.pointer a {
  text-decoration: none;
  color: #000;
}

.pointer a:visited {
  color: #5e5e5e;
}

.media {
  padding: 5px 0;
  margin: 0;
}

.media:hover {
  padding: 5px 0;
  background-color: #f8f8f8;
  margin: 0;
}

.media-footer {
  border-top: 1px #eee solid;
}

.unit-temp {
  padding: 20px 0 10px;
  border-bottom: 1px solid #eee;
}

.unit-title {
  padding-bottom: 10px;
}

.col-padding-top {
  padding-top: 20px !important;
}

.padding-checkbox {
  padding-left: 5px;
}

.modal-texttitle {
  color: #fff;
}

.total-amount {
  font-size: 50px;
  font-weight: 700;
  margin: -15px 0;
}

.total-margin {
  margin: -30px 0;
}

.text-align {
  vertical-align: text-bottom;
}

.img-fluid {
  width: 100%;
}

.small-widget {
  padding: 10px 25px !important;
}

.btn-margin-bottom {
  margin-bottom: 5px;
}

.btn-relative {
  position: relative;
}

.btn-relative .amount {
  position: absolute;
  top: -15px;
  background: #ff0600;
  border-radius: 30px;
  padding: 0 10px;
  right: -12px;
  outline: none;
}

#arincare_shopping_cart,
#product-edit-unsave,
#lineman-consent-modal,
#lineman-stopjoin-modal {
  font-family: 'Kanit', sans-serif;
  font-size: 14px;
}

#arincare_shopping_cart .btn,
#product-edit-unsave .btn,
#lineman-consent-modal .btn,
#lineman-stopjoin-modal .btn {
  font-size: 14px;
}

#arincare_shopping_cart p,
#product-edit-unsave p,
#lineman-consent-modal p,
#lineman-stopjoin-modal p {
  font-size: 15px;
}

.bill {
  border-bottom: 2px solid #e0e0e0;
  font-family: thaisans_neueregular;
  font-size: 12px;
  padding-bottom: 20px;
}

.bill .title {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.bill .sub-title {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.bill .bill-table {
  width: 100%;
}

.bill .bill-table .last-col {
  padding-left: 10px;
}

.bill .bill-table .border-top {
  border-top: 1px solid #808080;
}

select option:disabled {
  background-color: #ccc;
}

.feature_flag_wholesale_price {
  display: none;
}

.feature_flag_credit_term {
  display: none;
}

.bg-white {
  background-color: #fff;
}

.list-group-item {
  border: transparent;
}

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 100px;
  right: 25px;
  display: none;
}

.breadcrumb {
  background: #fff;
  margin-bottom: 20px;
}

.btn-inline .btn {
  margin: 0 0 0 5px;
}

.btn {
  font-size: 17px;
  font-weight: lighter;
}

.btn-default:hover {
  background-color: #35baf3;
  color: #fff;
}

.btn-footer-group .col-sm-6 > .btn {
  padding: 4px 60px;
}

.btn-outline-warning {
  background-color: transparent;
  color: #eea236;
  border-color: #eea236;
}

.btn-outline-warning:hover {
  background-color: #eea236;
  color: #fff;
}

.btn-outline-danger {
  background-color: transparent;
  color: #d43f3a;
  border-color: #d43f3a;
}

.btn-outline-danger:hover {
  background-color: #d43f3a;
  color: #fff;
}

textarea.disable-resize {
  resize: none;
}

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  color: #ababab;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(var(--column), 1fr);
  grid-column-gap: var(--gap-x);
  grid-row-gap: var(--gap-y);
}

.form-grid--x > div + div {
  position: relative;
}

.form-grid--x > div + div::before {
  content: 'x';
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
}

.form-row {
  display: flex;
  white-space: nowrap;
}

.form-row--center {
  align-items: center;
}

.form-row--center > label {
  margin-bottom: 0;
}

.form-row--between {
  justify-content: space-between;
}

.form-row--fluid {
  width: 100%;
}

.form-control--sm {
  font-size: 12px !important;
  height: 27px;
}

.selectpicker-fluid .btn-group.bootstrap-select {
  width: 100% !important;
}

.selectpicker-fluid .btn-group.bootstrap-select > .dropdown-toggle {
  padding-left: 6px;
  padding-right: 6px;
}

.checkbox-group .checkbox-inline + .checkbox-inline {
  margin-left: 20px;
}

.checkbox-grid label + input[type="checkbox"] {
  margin-left: 8px;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  padding: 0 0 0 15px;
}

.col-lg-padding {
  padding: 0 0 0 30px;
}

.col-lg-1:first-child,
.col-lg-10:first-child,
.col-lg-11:first-child,
.col-lg-12:first-child,
.col-lg-2:first-child,
.col-lg-3:first-child,
.col-lg-4:first-child,
.col-lg-5:first-child,
.col-lg-6:first-child,
.col-lg-7:first-child,
.col-lg-8:first-child,
.col-lg-9:first-child,
.col-md-1:first-child,
.col-md-10:first-child,
.col-md-11:first-child,
.col-md-12:first-child,
.col-md-2:first-child,
.col-md-3:first-child,
.col-md-4:first-child,
.col-md-5:first-child,
.col-md-6:first-child,
.col-md-7:first-child,
.col-md-8:first-child,
.col-md-9:first-child,
.col-sm-1:first-child,
.col-sm-10:first-child,
.col-sm-11:first-child,
.col-sm-12:first-child,
.col-sm-2:first-child,
.col-sm-3:first-child,
.col-sm-4:first-child,
.col-sm-5:first-child,
.col-sm-6:first-child,
.col-sm-7:first-child,
.col-sm-8:first-child,
.col-sm-9:first-child,
.col-xs-1:first-child,
.col-xs-10:first-child,
.col-xs-11:first-child,
.col-xs-12:first-child,
.col-xs-2:first-child,
.col-xs-3:first-child,
.col-xs-4:first-child,
.col-xs-5:first-child,
.col-xs-6:first-child,
.col-xs-7:first-child,
.col-xs-8:first-child,
.col-xs-9:first-child {
  padding-left: 0px;
}

.d-grid {
  display: grid !important;
  grid-template-columns: repeat(var(--grid-col), 1fr);
  grid-column-gap: var(--col-gap);
  grid-row-gap: var(--row-gap);
}

.modal-dialog.modal-full {
  margin: 0;
  width: 100%;
  min-height: 100%;
}

.modal-dialog.modal-full .modal-content {
  position: absolute;
  width: 100%;
  min-height: 100%;
}

.modal-dialog.modal-full .modal-content .close {
  font-size: 40px;
}

[aria-hidden="true"] > div > .modal {
  z-index: 1040;
}

.modal--setting-drug-label {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.modal--setting-drug-label small {
  font-size: 12px;
}

.modal--setting-drug-label .modal-dialog {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.modal--setting-drug-label .form-control {
  padding-left: 6px;
  padding-right: 6px;
}

.panel {
  border-color: #e8e8e8;
}

.panel-body {
  background-color: #fff;
  padding: 20px 25px;
}

.panel-body .table {
  margin: -20px -25px 0;
  width: calc(100% + 50px) !important;
  max-width: none;
}

.panel-body .table tr th:first-child, .panel-body .table tr td:first-child {
  padding-left: 30px;
}

.panel-body .table tr th:last-child, .panel-body .table tr td:last-child {
  padding-right: 30px;
}

.panel-body .pagination {
  margin-bottom: 0;
}

.branch .panel-primary > .panel-heading {
  background-color: #01A9A9;
}

.popover {
  color: #000;
  min-width: 300px;
}

.table-hover > tbody > tr:hover td {
  background-color: #ededed;
}

.table > thead > tr > th {
  border-bottom: 2px solid #4098e4;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.branch .table > thead > tr > th {
  border-bottom: 2px solid #01A9A9;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: inherit;
}

.table > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.modal-body .table {
  margin: 0;
  width: 100% !important;
}

.table tbody tr.success td {
  border-top: 1px solid #d6e9c6;
  border-bottom: 1px solid #d6e9c6;
}

.table tbody tr.info td {
  border-top: 1px solid #bce8f1;
  border-bottom: 1px solid #bce8f1;
}

.table tbody tr.warning td {
  border-top: 1px solid #faebcc;
  border-bottom: 1px solid #faebcc;
}

.table tbody tr.danger td {
  border-top: 1px solid #ebccd1;
  border-bottom: 1px solid #ebccd1;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-0 {
  margin: calc(4px * 0) !important;
}

.mx-0 {
  margin-left: calc(4px * 0) !important;
  margin-right: calc(4px * 0) !important;
}

.my-0 {
  margin-top: calc(4px * 0) !important;
  margin-bottom: calc(4px * 0) !important;
}

.mt-0 {
  margin-top: calc(4px * 0) !important;
}

.ml-0 {
  margin-left: calc(4px * 0) !important;
}

.mr-0 {
  margin-right: calc(4px * 0) !important;
}

.mb-0 {
  margin-bottom: calc(4px * 0) !important;
}

.m-1 {
  margin: calc(4px * 1) !important;
}

.mx-1 {
  margin-left: calc(4px * 1) !important;
  margin-right: calc(4px * 1) !important;
}

.my-1 {
  margin-top: calc(4px * 1) !important;
  margin-bottom: calc(4px * 1) !important;
}

.mt-1 {
  margin-top: calc(4px * 1) !important;
}

.ml-1 {
  margin-left: calc(4px * 1) !important;
}

.mr-1 {
  margin-right: calc(4px * 1) !important;
}

.mb-1 {
  margin-bottom: calc(4px * 1) !important;
}

.m-2 {
  margin: calc(4px * 2) !important;
}

.mx-2 {
  margin-left: calc(4px * 2) !important;
  margin-right: calc(4px * 2) !important;
}

.my-2 {
  margin-top: calc(4px * 2) !important;
  margin-bottom: calc(4px * 2) !important;
}

.mt-2 {
  margin-top: calc(4px * 2) !important;
}

.ml-2 {
  margin-left: calc(4px * 2) !important;
}

.mr-2 {
  margin-right: calc(4px * 2) !important;
}

.mb-2 {
  margin-bottom: calc(4px * 2) !important;
}

.m-3 {
  margin: calc(4px * 3) !important;
}

.mx-3 {
  margin-left: calc(4px * 3) !important;
  margin-right: calc(4px * 3) !important;
}

.my-3 {
  margin-top: calc(4px * 3) !important;
  margin-bottom: calc(4px * 3) !important;
}

.mt-3 {
  margin-top: calc(4px * 3) !important;
}

.ml-3 {
  margin-left: calc(4px * 3) !important;
}

.mr-3 {
  margin-right: calc(4px * 3) !important;
}

.mb-3 {
  margin-bottom: calc(4px * 3) !important;
}

.m-4 {
  margin: calc(4px * 4) !important;
}

.mx-4 {
  margin-left: calc(4px * 4) !important;
  margin-right: calc(4px * 4) !important;
}

.my-4 {
  margin-top: calc(4px * 4) !important;
  margin-bottom: calc(4px * 4) !important;
}

.mt-4 {
  margin-top: calc(4px * 4) !important;
}

.ml-4 {
  margin-left: calc(4px * 4) !important;
}

.mr-4 {
  margin-right: calc(4px * 4) !important;
}

.mb-4 {
  margin-bottom: calc(4px * 4) !important;
}

.m-5 {
  margin: calc(4px * 5) !important;
}

.mx-5 {
  margin-left: calc(4px * 5) !important;
  margin-right: calc(4px * 5) !important;
}

.my-5 {
  margin-top: calc(4px * 5) !important;
  margin-bottom: calc(4px * 5) !important;
}

.mt-5 {
  margin-top: calc(4px * 5) !important;
}

.ml-5 {
  margin-left: calc(4px * 5) !important;
}

.mr-5 {
  margin-right: calc(4px * 5) !important;
}

.mb-5 {
  margin-bottom: calc(4px * 5) !important;
}

.m-6 {
  margin: calc(4px * 6) !important;
}

.mx-6 {
  margin-left: calc(4px * 6) !important;
  margin-right: calc(4px * 6) !important;
}

.my-6 {
  margin-top: calc(4px * 6) !important;
  margin-bottom: calc(4px * 6) !important;
}

.mt-6 {
  margin-top: calc(4px * 6) !important;
}

.ml-6 {
  margin-left: calc(4px * 6) !important;
}

.mr-6 {
  margin-right: calc(4px * 6) !important;
}

.mb-6 {
  margin-bottom: calc(4px * 6) !important;
}

.m-7 {
  margin: calc(4px * 7) !important;
}

.mx-7 {
  margin-left: calc(4px * 7) !important;
  margin-right: calc(4px * 7) !important;
}

.my-7 {
  margin-top: calc(4px * 7) !important;
  margin-bottom: calc(4px * 7) !important;
}

.mt-7 {
  margin-top: calc(4px * 7) !important;
}

.ml-7 {
  margin-left: calc(4px * 7) !important;
}

.mr-7 {
  margin-right: calc(4px * 7) !important;
}

.mb-7 {
  margin-bottom: calc(4px * 7) !important;
}

.m-8 {
  margin: calc(4px * 8) !important;
}

.mx-8 {
  margin-left: calc(4px * 8) !important;
  margin-right: calc(4px * 8) !important;
}

.my-8 {
  margin-top: calc(4px * 8) !important;
  margin-bottom: calc(4px * 8) !important;
}

.mt-8 {
  margin-top: calc(4px * 8) !important;
}

.ml-8 {
  margin-left: calc(4px * 8) !important;
}

.mr-8 {
  margin-right: calc(4px * 8) !important;
}

.mb-8 {
  margin-bottom: calc(4px * 8) !important;
}

.m-9 {
  margin: calc(4px * 9) !important;
}

.mx-9 {
  margin-left: calc(4px * 9) !important;
  margin-right: calc(4px * 9) !important;
}

.my-9 {
  margin-top: calc(4px * 9) !important;
  margin-bottom: calc(4px * 9) !important;
}

.mt-9 {
  margin-top: calc(4px * 9) !important;
}

.ml-9 {
  margin-left: calc(4px * 9) !important;
}

.mr-9 {
  margin-right: calc(4px * 9) !important;
}

.mb-9 {
  margin-bottom: calc(4px * 9) !important;
}

.m-10 {
  margin: calc(4px * 10) !important;
}

.mx-10 {
  margin-left: calc(4px * 10) !important;
  margin-right: calc(4px * 10) !important;
}

.my-10 {
  margin-top: calc(4px * 10) !important;
  margin-bottom: calc(4px * 10) !important;
}

.mt-10 {
  margin-top: calc(4px * 10) !important;
}

.ml-10 {
  margin-left: calc(4px * 10) !important;
}

.mr-10 {
  margin-right: calc(4px * 10) !important;
}

.mb-10 {
  margin-bottom: calc(4px * 10) !important;
}

.p-0 {
  padding: calc(4px * 0) !important;
}

.px-0 {
  padding-left: calc(4px * 0) !important;
  padding-right: calc(4px * 0) !important;
}

.py-0 {
  padding-top: calc(4px * 0) !important;
  padding-bottom: calc(4px * 0) !important;
}

.pt-0 {
  padding-top: calc(4px * 0) !important;
}

.pl-0 {
  padding-left: calc(4px * 0) !important;
}

.pr-0 {
  padding-right: calc(4px * 0) !important;
}

.pb-0 {
  padding-bottom: calc(4px * 0) !important;
}

.p-1 {
  padding: calc(4px * 1) !important;
}

.px-1 {
  padding-left: calc(4px * 1) !important;
  padding-right: calc(4px * 1) !important;
}

.py-1 {
  padding-top: calc(4px * 1) !important;
  padding-bottom: calc(4px * 1) !important;
}

.pt-1 {
  padding-top: calc(4px * 1) !important;
}

.pl-1 {
  padding-left: calc(4px * 1) !important;
}

.pr-1 {
  padding-right: calc(4px * 1) !important;
}

.pb-1 {
  padding-bottom: calc(4px * 1) !important;
}

.p-2 {
  padding: calc(4px * 2) !important;
}

.px-2 {
  padding-left: calc(4px * 2) !important;
  padding-right: calc(4px * 2) !important;
}

.py-2 {
  padding-top: calc(4px * 2) !important;
  padding-bottom: calc(4px * 2) !important;
}

.pt-2 {
  padding-top: calc(4px * 2) !important;
}

.pl-2 {
  padding-left: calc(4px * 2) !important;
}

.pr-2 {
  padding-right: calc(4px * 2) !important;
}

.pb-2 {
  padding-bottom: calc(4px * 2) !important;
}

.p-3 {
  padding: calc(4px * 3) !important;
}

.px-3 {
  padding-left: calc(4px * 3) !important;
  padding-right: calc(4px * 3) !important;
}

.py-3 {
  padding-top: calc(4px * 3) !important;
  padding-bottom: calc(4px * 3) !important;
}

.pt-3 {
  padding-top: calc(4px * 3) !important;
}

.pl-3 {
  padding-left: calc(4px * 3) !important;
}

.pr-3 {
  padding-right: calc(4px * 3) !important;
}

.pb-3 {
  padding-bottom: calc(4px * 3) !important;
}

.p-4 {
  padding: calc(4px * 4) !important;
}

.px-4 {
  padding-left: calc(4px * 4) !important;
  padding-right: calc(4px * 4) !important;
}

.py-4 {
  padding-top: calc(4px * 4) !important;
  padding-bottom: calc(4px * 4) !important;
}

.pt-4 {
  padding-top: calc(4px * 4) !important;
}

.pl-4 {
  padding-left: calc(4px * 4) !important;
}

.pr-4 {
  padding-right: calc(4px * 4) !important;
}

.pb-4 {
  padding-bottom: calc(4px * 4) !important;
}

.p-5 {
  padding: calc(4px * 5) !important;
}

.px-5 {
  padding-left: calc(4px * 5) !important;
  padding-right: calc(4px * 5) !important;
}

.py-5 {
  padding-top: calc(4px * 5) !important;
  padding-bottom: calc(4px * 5) !important;
}

.pt-5 {
  padding-top: calc(4px * 5) !important;
}

.pl-5 {
  padding-left: calc(4px * 5) !important;
}

.pr-5 {
  padding-right: calc(4px * 5) !important;
}

.pb-5 {
  padding-bottom: calc(4px * 5) !important;
}

.p-6 {
  padding: calc(4px * 6) !important;
}

.px-6 {
  padding-left: calc(4px * 6) !important;
  padding-right: calc(4px * 6) !important;
}

.py-6 {
  padding-top: calc(4px * 6) !important;
  padding-bottom: calc(4px * 6) !important;
}

.pt-6 {
  padding-top: calc(4px * 6) !important;
}

.pl-6 {
  padding-left: calc(4px * 6) !important;
}

.pr-6 {
  padding-right: calc(4px * 6) !important;
}

.pb-6 {
  padding-bottom: calc(4px * 6) !important;
}

.p-7 {
  padding: calc(4px * 7) !important;
}

.px-7 {
  padding-left: calc(4px * 7) !important;
  padding-right: calc(4px * 7) !important;
}

.py-7 {
  padding-top: calc(4px * 7) !important;
  padding-bottom: calc(4px * 7) !important;
}

.pt-7 {
  padding-top: calc(4px * 7) !important;
}

.pl-7 {
  padding-left: calc(4px * 7) !important;
}

.pr-7 {
  padding-right: calc(4px * 7) !important;
}

.pb-7 {
  padding-bottom: calc(4px * 7) !important;
}

.p-8 {
  padding: calc(4px * 8) !important;
}

.px-8 {
  padding-left: calc(4px * 8) !important;
  padding-right: calc(4px * 8) !important;
}

.py-8 {
  padding-top: calc(4px * 8) !important;
  padding-bottom: calc(4px * 8) !important;
}

.pt-8 {
  padding-top: calc(4px * 8) !important;
}

.pl-8 {
  padding-left: calc(4px * 8) !important;
}

.pr-8 {
  padding-right: calc(4px * 8) !important;
}

.pb-8 {
  padding-bottom: calc(4px * 8) !important;
}

.p-9 {
  padding: calc(4px * 9) !important;
}

.px-9 {
  padding-left: calc(4px * 9) !important;
  padding-right: calc(4px * 9) !important;
}

.py-9 {
  padding-top: calc(4px * 9) !important;
  padding-bottom: calc(4px * 9) !important;
}

.pt-9 {
  padding-top: calc(4px * 9) !important;
}

.pl-9 {
  padding-left: calc(4px * 9) !important;
}

.pr-9 {
  padding-right: calc(4px * 9) !important;
}

.pb-9 {
  padding-bottom: calc(4px * 9) !important;
}

.p-10 {
  padding: calc(4px * 10) !important;
}

.px-10 {
  padding-left: calc(4px * 10) !important;
  padding-right: calc(4px * 10) !important;
}

.py-10 {
  padding-top: calc(4px * 10) !important;
  padding-bottom: calc(4px * 10) !important;
}

.pt-10 {
  padding-top: calc(4px * 10) !important;
}

.pl-10 {
  padding-left: calc(4px * 10) !important;
}

.pr-10 {
  padding-right: calc(4px * 10) !important;
}

.pb-10 {
  padding-bottom: calc(4px * 10) !important;
}

.d-flex {
  display: flex !important;
  grid-column-gap: var(--col-gap);
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.w-45 {
  width: 45px !important;
}

.border-black {
  border-color: #000 !important;
}

.fs-1 {
  font-size: 1px !important;
}

.fs-2 {
  font-size: 2px !important;
}

.fs-3 {
  font-size: 3px !important;
}

.fs-4 {
  font-size: 4px !important;
}

.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-41 {
  font-size: 41px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-43 {
  font-size: 43px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-46 {
  font-size: 46px !important;
}

.fs-47 {
  font-size: 47px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fs-49 {
  font-size: 49px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-51 {
  font-size: 51px !important;
}

.fs-52 {
  font-size: 52px !important;
}

.fs-53 {
  font-size: 53px !important;
}

.fs-54 {
  font-size: 54px !important;
}

.fs-55 {
  font-size: 55px !important;
}

.fs-56 {
  font-size: 56px !important;
}

.fs-57 {
  font-size: 57px !important;
}

.fs-58 {
  font-size: 58px !important;
}

.fs-59 {
  font-size: 59px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-61 {
  font-size: 61px !important;
}

.fs-62 {
  font-size: 62px !important;
}

.fs-63 {
  font-size: 63px !important;
}

.fs-64 {
  font-size: 64px !important;
}

.fs-65 {
  font-size: 65px !important;
}

.fs-66 {
  font-size: 66px !important;
}

.fs-67 {
  font-size: 67px !important;
}

.fs-68 {
  font-size: 68px !important;
}

.fs-69 {
  font-size: 69px !important;
}

.fs-70 {
  font-size: 70px !important;
}

.fs-71 {
  font-size: 71px !important;
}

.fs-72 {
  font-size: 72px !important;
}

.fs-73 {
  font-size: 73px !important;
}

.fs-74 {
  font-size: 74px !important;
}

.fs-75 {
  font-size: 75px !important;
}

.fs-76 {
  font-size: 76px !important;
}

.fs-77 {
  font-size: 77px !important;
}

.fs-78 {
  font-size: 78px !important;
}

.fs-79 {
  font-size: 79px !important;
}

.fs-80 {
  font-size: 80px !important;
}

.fs-81 {
  font-size: 81px !important;
}

.fs-82 {
  font-size: 82px !important;
}

.fs-83 {
  font-size: 83px !important;
}

.fs-84 {
  font-size: 84px !important;
}

.fs-85 {
  font-size: 85px !important;
}

.fs-86 {
  font-size: 86px !important;
}

.fs-87 {
  font-size: 87px !important;
}

.fs-88 {
  font-size: 88px !important;
}

.fs-89 {
  font-size: 89px !important;
}

.fs-90 {
  font-size: 90px !important;
}

.fs-91 {
  font-size: 91px !important;
}

.fs-92 {
  font-size: 92px !important;
}

.fs-93 {
  font-size: 93px !important;
}

.fs-94 {
  font-size: 94px !important;
}

.fs-95 {
  font-size: 95px !important;
}

.fs-96 {
  font-size: 96px !important;
}

.fs-97 {
  font-size: 97px !important;
}

.fs-98 {
  font-size: 98px !important;
}

.fs-99 {
  font-size: 99px !important;
}

.fs-100 {
  font-size: 100px !important;
}

.filter-detail {
  color: #656565;
}

.filter-detail ul > li {
  display: inline-block;
  padding: 0 20px 0 8px;
  position: relative;
}

.filter-detail ul > li:after {
  content: '|';
  position: absolute;
  right: 0;
}

.filter-detail ul > li:last-of-type:after {
  content: '';
}

/** PRELOADER by http://cssload.net/en/spinners **/
.preloader {
  background: #fff;
  padding-top: 10%;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  width: 100%;
  min-height: 100%;
  z-index: 9999;
}

.cssload-square {
  margin: 96px auto;
  width: 28px;
  height: 28px;
  transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}

.cssload-square-part {
  position: absolute;
  width: 28px;
  height: 28px;
  z-index: 1;
  animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  -o-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  -ms-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  -webkit-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  -moz-animation: cssload-part-anim 1.04s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
}

.cssload-square-green {
  background: #57bfe8;
  right: 0;
  bottom: 0;
  animation-direction: alternate-reverse;
  -o-animation-direction: alternate-reverse;
  -ms-animation-direction: alternate-reverse;
  -webkit-animation-direction: alternate-reverse;
  -moz-animation-direction: alternate-reverse;
}

.cssload-square-pink {
  background: #0083c9;
  left: 0;
  top: 0;
}

.cssload-square-blend {
  background: #073c65;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  animation: blend-anim 1.04s ease-in infinite;
  -o-animation: blend-anim 1.04s ease-in infinite;
  -ms-animation: blend-anim 1.04s ease-in infinite;
  -webkit-animation: blend-anim 1.04s ease-in infinite;
  -moz-animation: blend-anim 1.04s ease-in infinite;
}

@keyframes blend-anim {
  0% {
    transform: scale(0.01, 0.01) rotateY(0);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    transform: scale(1, 1) rotateY(0);
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-o-keyframes blend-anim {
  0% {
    -o-transform: scale(0.01, 0.01) rotateY(0);
    -o-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -o-transform: scale(1, 1) rotateY(0);
    -o-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -o-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-ms-keyframes blend-anim {
  0% {
    -ms-transform: scale(0.01, 0.01) rotateY(0);
    -ms-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -ms-transform: scale(1, 1) rotateY(0);
    -ms-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -ms-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-webkit-keyframes blend-anim {
  0% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -webkit-transform: scale(1, 1) rotateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -webkit-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@-moz-keyframes blend-anim {
  0% {
    -moz-transform: scale(0.01, 0.01) rotateY(0);
    -moz-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  50% {
    -moz-transform: scale(1, 1) rotateY(0);
    -moz-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  100% {
    -moz-transform: scale(0.01, 0.01) rotateY(0);
  }
}

@keyframes cssload-part-anim {
  0% {
    transform: translate3d(-14px, -14px, 0);
  }
  100% {
    transform: translate3d(14px, 14px, 0);
  }
}

@-o-keyframes cssload-part-anim {
  0% {
    -o-transform: translate3d(-14px, -14px, 0);
  }
  100% {
    -o-transform: translate3d(14px, 14px, 0);
  }
}

@-ms-keyframes cssload-part-anim {
  0% {
    -ms-transform: translate3d(-14px, -14px, 0);
  }
  100% {
    -ms-transform: translate3d(14px, 14px, 0);
  }
}

@-webkit-keyframes cssload-part-anim {
  0% {
    -webkit-transform: translate3d(-14px, -14px, 0);
  }
  100% {
    -webkit-transform: translate3d(14px, 14px, 0);
  }
}

@-moz-keyframes cssload-part-anim {
  0% {
    -moz-transform: translate3d(-14px, -14px, 0);
  }
  100% {
    -moz-transform: translate3d(14px, 14px, 0);
  }
}

.text-status, .text-status-PRE_PROGRESS, .text-status-IN_PROGRESS,
.text-status-PAID, .text-status-COMPLETED,
.text-status-PAID_IN_FULL, .text-status-CANCELED,
.text-status-UNPAID {
  font-weight: 900;
}

.text-status-large {
  margin-left: 15px;
  font-size: 40px;
  font-weight: 900;
}

.text-status-PRE_PROGRESS {
  color: #ff9000;
}

.text-status-IN_PROGRESS,
.text-status-PAID {
  color: #F8CA00;
}

.text-status-COMPLETED,
.text-status-PAID_IN_FULL {
  color: #61c161;
}

.text-status-CANCELED,
.text-status-UNPAID {
  color: #e35051;
}

.text-status-CANCELED02 {
  color: #FF0000;
  font-family: 'Kanit', sans-serif;
  font-size: 18px;
}

.text-status-INVOICE_ISSUED {
  color: #337ab7;
}

/* Top Menu */
.top-menu {
  background-color: #0081c0;
  color: #fff;
  font-size: 20px;
  margin: 0 0 44px 0;
  width: 100%;
  min-height: 50px;
}

.menu-logo {
  float: left;
  margin: 13px 10px 10px 20px;
}

.menu-logo img {
  margin-top: -10px;
  margin-bottom: -10px;
}

.menu-title {
  float: left;
  margin: 0;
  margin-right: 15px;
}

.menu-title > a {
  display: inline-block;
  color: #fff;
  padding: 15px 5px;
}

.menu-title > a:hover {
  text-decoration: none;
}

.menu-title > a:nth-child(2) {
  margin-left: -5px;
}

.menu-hamburger {
  display: none;
  cursor: pointer;
  float: right;
  font-size: 20px;
  position: relative;
  top: 5px;
  right: 8px;
  z-index: 10000;
}

.menu-hamburger > span {
  position: absolute;
  top: 5px;
  right: 5px;
}

.menu-hamburger .menu-close {
  display: none;
}

.menu-left {
  float: left;
}

.menu-right {
  float: right;
}

.menu {
  margin-bottom: 0;
  padding: 0;
}

.menu li {
  float: left;
  list-style: none;
}

.menu li a,
.menu li a:visited {
  display: block;
  color: #fff;
  padding: 15px 15px;
  vertical-align: middle;
  text-decoration: none;
}

.menu li a:hover,
.menu li.active a {
  background-color: #006eab;
  color: #fff;
  text-decoration: none;
}

.menu-second-layer {
  display: none;
  background-color: #006eab;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
}

.menu-second-layer li {
  float: left;
}

.menu-second-layer li a:hover,
.menu-second-layer li.active a {
  background-color: #f5f5f5;
  color: #000;
}

.menu .open .menu-second-layer {
  display: block;
}

.menu .open .menu-second-layer li a {
  padding: 6px 15px;
}

.menu .open {
  background-color: #006eab;
}

.menu-dropdown-inside:after {
  content: ' ';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #0081c0;
  position: absolute;
  right: 18px;
  top: -10px;
  width: 0;
  height: 0;
}

.menu-dropdown-inside {
  display: none;
  background-color: #0081c0;
  border-radius: 5px;
  padding: 0;
  position: absolute;
  top: 70px;
  right: 10px;
  left: inherit;
  min-width: 150px;
  max-width: 250px;
  z-index: 9999;
}

.menu-dropdown-inside li {
  display: block;
  width: 100%;
}

.menu-dropdown-inside > li.active > a {
  background-color: #f5f5f5;
  color: #000;
}

.menu-dropdown-inside > li > a:hover {
  color: white;
  background-color: #006eab;
}

.menu-dropdown-inside li.divider {
  border: 1px solid #0070a7;
  margin: 0 5%;
  width: 90%;
}

.menu-multi-lang {
  width: 52px;
}

.menu-multi-lang > a > img {
  position: relative;
  vertical-align: top;
  padding-top: 2px;
}

.menu-multi-lang .menu-dropdown-inside {
  right: 210px;
  min-width: 70px;
}

.menu-multi-lang .menu-dropdown-inside li a img {
  margin-right: 10px;
  width: 50px;
}

.top-menu.branch-menu {
  background-color: #01a9a9;
}

.branch-menu .menu li a:hover,
.branch-menu .menu li.active a {
  background-color: #018a8a;
}

.branch-menu .menu-second-layer {
  background-color: #018a8a;
}

.branch-menu .menu-second-layer li a:hover,
.branch-menu .menu-second-layer li.active a {
  background-color: #f5f5f5;
}

.branch-menu .menu .open {
  background-color: #018a8a;
}

.branch-menu .menu-dropdown-inside {
  background-color: #01a9a9;
}

.branch-menu .menu-dropdown-inside > li > a:hover {
  background-color: #018a8a;
}

.branch-menu .menu-dropdown-inside li.divider {
  border: 1px solid #019090;
}

@media (max-width: 1000px) {
  .menu-hamburger {
    display: block;
  }
  .menu-title {
    border-right: 0px;
  }
  .menu-content {
    background-color: #000;
    position: fixed;
    right: -60%;
    overflow: scroll;
    width: 60%;
    height: 100%;
    min-height: 100%;
    z-index: 9999;
  }
  .menu {
    border-bottom: 2px solid #fff;
    float: none;
    position: static;
    width: 100%;
  }
  .menu li {
    display: block;
    float: none;
  }
  .menu li a,
  .menu li a:visited {
    background-color: #0081c0;
    display: block;
    color: #fff;
    padding: 15px 15px;
    vertical-align: middle;
    text-decoration: none;
    width: 100%;
  }
  .menu li.active a,
  .menu li a:hover {
    background-color: #006eab;
    color: #fff;
  }
  .menu li a:hover > ul li a {
    background-color: #0081c0;
  }
  .menu-second-layer {
    display: block;
    position: static;
    width: 100%;
  }
  .menu-second-layer li {
    width: 100%;
  }
  .open .menu-second-layer li a,
  .menu-second-layer li a {
    background-color: #0081c0;
    padding: 15px 15px 15px 30px;
  }
  .menu-second-layer li.active a,
  .menu-second-layer li a:hover {
    background-color: #006eab;
    color: #fff;
  }
  .menu-dropdown {
    border: 0;
  }
  .branch-menu .menu li a,
  .branch-menu .menu li a:visited {
    background-color: #01a9a9;
  }
  .branch-menu .menu li.active a,
  .branch-menu .menu li a:hover {
    background-color: #018a8a;
  }
  .branch-menu .menu li a:hover > ul li a {
    background-color: #01a9a9;
  }
  .branch-menu .menu-second-layer li a:hover {
    background-color: #018a8a;
  }
}

.ui-datepicker {
  z-index: 10000 !important;
}

.product-list-container {
  background-color: white;
  padding: 10px;
  margin: 5px 0 15px 0;
}

.product-list-container > div > div.row:first-child {
  margin-bottom: 5px;
}

.buyer-document > table > tbody > tr > .valid {
  font-weight: bolder;
  color: #61c161;
}

.buyer-document > table > tbody > tr > .invalid {
  font-weight: bolder;
  color: #e35051;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__container .react-autosuggest__suggestions-container {
  background: #fff;
  border: 1px solid #D0D7E0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  list-style: none;
  padding: 0;
  position: absolute;
  top: 33px;
  left: 0;
  width: 100%;
  z-index: 10000;
}

.react-autosuggest__container .react-autosuggest__suggestions-container .react-autosuggest__suggestion {
  padding: 5px 10px;
  border-bottom: 1px dotted #d9d9d9;
  -webkit-transition: .2s;
  transition: .2s;
}

.react-autosuggest__container .react-autosuggest__suggestions-container .react-autosuggest__suggestion:last-childe {
  border-bottom: 0px;
}

.react-autosuggest__container .react-autosuggest__suggestions-container .react-autosuggest__suggestion--focused {
  background: #6890D6;
  color: #fff;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle, .react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
  margin-top: -20px;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 13px;
}

.react-datepicker__current-month--hasYearDropdown {
  margin-bottom: 16px;
}

.react-datepicker__navigation {
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: 6px solid transparent;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month {
  margin: 5px;
  text-align: center;
}

.react-datepicker__day-name, .react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  margin: 2px;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 4px;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--selected, .react-datepicker__day--in-range {
  border-radius: 4px;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view {
  width: 50%;
  left: 25%;
  position: absolute;
  bottom: 25px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.react-datepicker__year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow {
  border-top-color: #ccc;
  margin-bottom: 3px;
  left: 5px;
  top: 9px;
  position: relative;
  border-width: 6px;
}

.react-datepicker__year-read-view--selected-year {
  right: 6px;
  position: relative;
}

.react-datepicker__year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-datepicker__year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.react-datepicker__year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected {
  position: absolute;
  left: 30px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
}

.react-datepicker__tether-element {
  z-index: 2147483647;
}

#page-error {
  color: #b5b4b4;
  margin: 0 auto;
  text-align: center;
  max-width: 1200px;
}

#page-error .error-code {
  font-size: 190px;
}

#page-error .error-code img {
  width: 140px;
  margin: 0 -48px 1px -48px;
}

#page-error .error-text {
  margin-top: 80px;
}

#page-error .error-text .error-header {
  font-size: 36px;
  padding: 10px 0;
  margin: 10px 0;
}

#page-error .error-text .error-detail,
#page-error .error-text .error-detail a {
  font-size: 20px;
  text-decoration: none;
}

#page-error .error-text .error-detail a {
  border-right: 1px solid #888;
  padding: 5px 10px;
  border-color: #EEEEEE;
}

#page-error .error-text .error-detail a:last-child {
  border-right: 0;
}

@media (max-width: 768px) {
  #page-error .error-text {
    margin-top: 0px;
  }
}

#article-login {
  margin: 0 auto;
  max-width: 500px;
}

#article-login .image-logo-login {
  margin-bottom: 30px;
  width: 100%;
}

.panel-login {
  border: 1px solid #f3f3f3;
  padding: 20px;
}

.panel-login #login-btn {
  padding: 10px 0;
  margin-bottom: 10px;
}

.gr-editor-title {
  color: #0471CE;
  border-bottom: 2px solid #0471CE;
  padding: 20px 0;
}

.gr-editor-list {
  list-style: none;
  padding: 0;
}

.gr-editor-list .gr-editor-item {
  background: #fff;
  border-left: 4px solid #b8b8b8;
  box-shadow: 0 0 5px 0 #888;
  color: #333;
  display: block;
  margin: 20px auto 0;
  padding: 20px;
  transition: 1s;
}

.gr-editor-list .gr-editor-item .title {
  padding: 10px;
}

.gr-editor-list .gr-editor-item:hover {
  text-decoration: none;
}

.gr-editor-list .gr-editor-item.disable {
  background: #d0d0d0;
}

.gr-editor-list .gr-editor-item.disable:hover {
  cursor: not-allowed;
}

.gr-editor-list .gr-editor-item.PRE_PROGRESS, .gr-editor-list .gr-editor-item.warning {
  border-left-color: #ff9000;
}

.gr-editor-list .gr-editor-item.IN_PROGRESS, .gr-editor-list .gr-editor-item.ready {
  border-left-color: #F8CA00;
}

.gr-editor-list .gr-editor-item.COMPLETED, .gr-editor-list .gr-editor-item.success {
  border-left-color: #61c161;
}

.gr-editor-list .gr-editor-item.CANCELED, .gr-editor-list .gr-editor-item.danger {
  border-left-color: #e35051;
}

.gr-editor-list .gr-editor-item:last-child {
  margin-bottom: 20px;
}

.gr-editor-list .gr-editor-item.highlight {
  box-shadow: 0 0 5px 0 #ff9000;
}

.btn-to-top {
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: #0071b2;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  z-index: 9999;
  text-align: center;
  transition: all ease 250ms;
}

.btn-to-top i {
  color: #fff;
  font-size: 30px;
  line-height: 56px;
}

.alert {
  padding: 15px;
}

tbody {
  border-bottom: 1px solid #ddd;
}

.pagination li a,
.pagination li span {
  line-height: 1.1;
}

tbody tr.checked {
  background: #fcf8e3 !important;
  border: 1px solid #faebcc;
}

tbody tr.checked.new {
  background: #e0f0ff !important;
  border: 1px solid #a6d4ff;
}

tbody tr.checked td {
  border: none !important;
}

tbody tr.checked + tr td {
  border-top: none !important;
}

tbody tr:last-child td {
  border-bottom: 1px solid #ddd;
}

#product-edit .table {
  margin: 0 -15px 25px;
  width: calc(100% + 40px) !important;
}

#product-edit .table tr th:first-child,
#product-edit .table tr td:first-child {
  padding-left: 15px;
}

#product-edit .table tr th:last-child,
#product-edit .table tr td:last-child {
  padding-right: 15px;
}

#product-edit .box .box-title {
  background: #fff;
  font-size: 26px;
  padding: 5px 20px;
  position: absolute;
  top: -41px;
  left: 10px;
  font-weight: normal;
}

#product-edit .bootstrap-select.btn-group.disabled,
#product-edit .bootstrap-select.btn-group > .disabled {
  background: #eee;
}

#product-edit .bootstrap-select.btn-group.disabled:hover,
#product-edit .bootstrap-select.btn-group > .disabled:hover {
  color: #000;
}

#product-edit select option:disabled {
  background-color: #ccc;
}

#product-edit .input-sm {
  height: 30px;
}

#product-edit .input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
}

#product-edit .form-control {
  font-size: 18px;
  height: 34px;
  color: #5e5e5e;
}

#product-edit textarea.form-control {
  height: auto;
}

#product-edit .col-md-6:first-child {
  padding: 0 0 0 15px;
}

#product-edit h5 {
  margin-bottom: 10px;
}

#product-edit #save {
  margin-top: 20px;
  text-align: center;
}

#product-edit #save button#reset-bt {
  min-width: 70px;
}

#product-edit #save button#save-bt {
  min-width: 100px;
}

#product-edit .td-barcode {
  text-align: center;
}

#product-edit .td-barcode div {
  height: 0;
  overflow: visible;
}

#product-edit .td-barcode div svg {
  position: relative;
  top: -4px;
  left: -5px;
}

/* css sidebar form https://bootsnipp.com/snippets/featured/sexy-sidebar-navigation */
.nav-sidebar {
  width: auto;
  margin: 8px 0 8px 0;
  padding-right: 15px;
  border-right: 1px solid #ddd;
  transition: all 0.2s ease;
}

.nav-sidebar a {
  color: #333;
  -webkit-transition: all 0.08s linear;
  -moz-transition: all 0.08s linear;
  -o-transition: all 0.08s linear;
  transition: all 0.08s linear;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.nav-sidebar .active a {
  cursor: default;
  background-color: #428bca !important;
  color: #fff;
  text-shadow: 1px 1px 1px #666;
}

.nav-sidebar .active a:hover {
  background-color: #428bca;
}

.nav-sidebar .changed a {
  color: #f00;
  background-color: #ffe0e0;
}

.nav-sidebar .text-overflow a,
.nav-sidebar .text-overflow .media-body {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.nav-sidebar.pull-right {
  border-right: 0;
  border-left: 1px solid #ddd;
}

.nav-sidebar.pull-right a {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.nav-sidebar.sticky {
  position: fixed;
  top: 100px;
  left: 0;
  background: #fff;
  padding-top: 0px;
  margin: auto;
  width: 300px;
  /*height: calc(100vh - 200px);*/
  border-right: 1px solid #ddd;
}

/* Right-aligned sidebar */
/* Sidebar sticky on scroll */
.tab-page-group .tab-page {
  display: none;
}

.images-list {
  margin-top: 20px;
}

.images-list .card {
  margin-bottom: 10px;
  position: relative;
}

.images-list .card.checked {
  background: #fcf8e3 !important;
}

.images-list .card .inner {
  width: 300px;
  height: 300px;
  line-height: 300px;
  text-align: center;
  background-color: #eee;
}

.images-list .card .bt {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 25px;
  height: 25px;
  line-height: 30px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

.images-list .card:hover .bt {
  display: block;
}

.images-list .card .delete-bt {
  top: 10px;
  right: 10px;
  color: red;
}

.images-list .card .up-bt {
  top: 138px;
  left: 10px;
}

.images-list .card .down-bt {
  top: 138px;
  right: 10px;
}

.for-print__grid {
  display: grid;
  grid-template-columns: 675px 298px;
  grid-column-gap: 14px;
}

.for-print__card {
  border: 2px solid #007BFF;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.for-print__card--table {
  padding: 0px 5px;
}

.for-print__card small {
  font-size: 12px;
}

.for-print__card .form-group {
  margin-bottom: 0;
}

.for-print__card .form-group > label {
  white-space: nowrap;
  font-weight: 400;
}

.for-print__card .form-group-after {
  margin-left: 5px;
}

.for-print__card .form-group--inline {
  display: flex;
  align-items: center;
}

.for-print__card .form-group--inline > label {
  margin-right: 5px;
  margin-bottom: 0;
}

.for-print__card .form-group--inline + .form-group--inline {
  margin-left: 10px;
}

.for-print__card .form-group--between {
  justify-content: space-between;
}

.for-print__card .form-group--grow {
  flex-grow: 1;
}

.for-print__card .form-group--more-info {
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.for-print__card .form-group--more-info .form-control {
  border-width: 0;
  padding-left: 0;
  padding-right: 0;
  height: 100% !important;
  resize: none;
  box-shadow: none;
}

.for-print__card .form-group--more-info .form-control:focus {
  border-width: 0;
  box-shadow: none;
}

.for-print__card .form-control {
  padding-left: 6px;
  padding-right: 6px;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.for-print__card .form-control[style^="--width"] {
  width: var(--width);
}

.for-print__card .form-control--static {
  border-width: 0;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  height: auto !important;
}

.for-print__card .checkbox-group .checkbox-inline + .checkbox-inline {
  margin-left: 20px;
}

.for-print__card .checkbox-inline input[type="checkbox"] {
  margin-top: 7px;
}

.for-print__card textarea.no-resize {
  resize: none !important;
}

.for-print__card textarea.is-overflow {
  border-color: red !important;
}

.for-print__card textarea.is-overflow:focus {
  border-color: red !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.for-print__table {
  width: 100%;
}

.for-print__table tr:last-child td {
  border-bottom-color: transparent;
}

.for-print__table tr th,
.for-print__table tr td {
  padding: 10px 5px;
}

.for-print__table thead tr th {
  position: relative;
}

.for-print__table thead tr th::before {
  content: '';
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
  border-bottom: 1px solid #000;
}

.for-print__table-header {
  min-height: 97px;
}

.for-print__actions {
  grid-column: span 2;
  text-align: right !important;
  margin-top: 16px !important;
}

.for-print__actions .btn + .btn {
  margin-left: 13px;
}

.for-print__notes {
  grid-template-columns: auto 1fr;
  margin-top: 1rem;
  color: red;
}

.for-print__notes.show {
  display: grid !important;
}

.create-drug-label__grid {
  display: grid;
  grid-template-columns: 556px 1fr;
  grid-column-gap: 22px;
}

.create-drug-label__grid .form-control,
.create-drug-label__grid .btn {
  font-size: 16px;
  font-weight: 400;
}

.create-drug-label__actions {
  grid-column: span 2;
  margin-top: 50px;
  text-align: right;
}

.create-drug-label__actions .btn + .btn {
  margin-left: 13px;
}

.printing__body {
  border: 2px solid #007BFF;
  border-radius: 5px;
  padding: 5px 10px;
}

.printing__table {
  width: 100%;
}

.printing__table tr th,
.printing__table tr td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.printing__table thead {
  border-bottom: 1px solid #000;
}

.printing__table thead tr:last-child th {
  padding-bottom: 10px;
}

.printing__table tbody {
  border-bottom-color: transparent;
  border-bottom-width: 0;
}

.printing__table tbody tr:first-child td {
  padding-top: 10px;
}

.printing__table tbody tr:last-child td {
  border-bottom-color: transparent;
  border-bottom-width: 0;
}

.panel-create-drug-label {
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}

.panel-create-drug-label .panel-heading {
  background-color: #007BFF;
  padding: 15px 20px;
}

.panel-create-drug-label .panel-heading h4 {
  font-size: 18px;
}

.panel-create-drug-label .panel-body {
  padding: 32px 20px 28px;
}

.panel-create-drug-label .panel-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.panel-create-drug-label .panel-form {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 22px;
  font-size: 16px;
}

.panel-create-drug-label .panel-form dt {
  font-weight: 400;
  padding-top: 8px;
}

.panel-create-drug-label .panel-form .form-control {
  font-size: 16px;
}

.panel-create-drug-label .panel-form .form-control[disabled] {
  pointer-events: none !important;
  background-color: #eeeeee !important;
}

.panel-create-drug-label .panel-form.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.panel-create-drug-label .panel-form .is-disabled {
  pointer-events: none !important;
  opacity: .5 !important;
}

.panel-create-drug-label .panel-table {
  border: 2px solid #007BFF;
  border-radius: 6px;
  padding: 10px 20px 20px;
}

.panel-create-drug-label .panel-table table {
  border-width: 0;
}

.panel-create-drug-label .panel-table table tr th,
.panel-create-drug-label .panel-table table tr td {
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 6px;
}

.panel-create-drug-label .panel-table table tr:last-child td {
  border-bottom-width: 0;
}

.panel-create-drug-label .panel-table table tbody {
  border-bottom-width: 0;
  font-size: 20px;
}

.panel-create-drug-label .panel-table input,
.panel-create-drug-label .panel-table .form-control {
  font-size: 14px;
}

.panel-create-drug-label .panel-table input[type="checkbox"] {
  pointer-events: none;
}

.panel-create-drug-label .text-primary,
.panel-create-drug-label a {
  color: #007BFF;
}

.panel-create-drug-label select.form-control {
  padding-left: 11px;
  appearance: none;
  position: relative;
  background: #fff url("../../images/select-arrow.png") no-repeat right 6px center/auto;
}

.panel-create-drug-label label {
  font-weight: 400;
}

.panel-create-drug-label .btn-primary {
  background-color: #007BFF;
}

.panel-create-drug-label .btn-primary:hover, .panel-create-drug-label .btn-primary:focus {
  background-color: #0062cc;
}

.panel-create-drug-label .btn-info {
  background-color: #55C2EB;
}

.panel-create-drug-label .btn-info:hover, .panel-create-drug-label .btn-info:focus {
  background-color: #27b2e6;
}

.panel-create-drug-label .btn-success {
  background-color: #5CB85C;
}

.panel-create-drug-label .btn-success:hover, .panel-create-drug-label .btn-success:focus {
  background-color: #449d44;
}

.panel-create-drug-label .btn-danger {
  background-color: #DC3545;
}

.panel-create-drug-label .btn-danger:hover, .panel-create-drug-label .btn-danger:focus {
  background-color: #bd2130;
}

.panel-create-drug-label .btn[disabled] {
  background-color: #E4E4E4;
  border-color: #E4E4E4;
  opacity: 1;
}

@media (min-width: 1200px) {
  .panel-create-drug-label .panel-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 50px;
  }
  .panel-create-drug-label .panel-table {
    display: inline-block;
  }
}

.modal-edit-branch {
  font-family: 'Kanit', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}

.modal-edit-branch .form-group {
  margin-bottom: 8px;
}

.modal-edit-branch .form-control {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
}

.modal-edit-branch label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}

.modal-edit-branch select.form-control {
  padding-left: 11px;
  appearance: none;
  position: relative;
  background: #fff url("../../images/select-arrow.png") no-repeat right 6px center/auto;
}

.modal-edit-branch textarea.no-resize {
  resize: none;
}

.modal-edit-branch .modal-dialog {
  width: 617px;
}

.modal-edit-branch .modal-header {
  margin: 0 10px;
}

.modal-edit-branch .modal-title {
  font-size: 16px;
  padding: 10px 0 15px;
  color: #000;
}

.modal-edit-branch .modal-body {
  padding: 12px 10px 10px;
}

.modal-edit-branch .modal-footer {
  border-top: none;
  padding: 22px 10px;
}

.alert-save-drug-label {
  position: fixed;
  top: 20px;
  left: 15px;
  right: 15px;
  padding: 18px 14px;
  font: 300 16px/24px 'Kanit';
  line-height: 1.5;
  z-index: 9999;
}

@media (min-width: 992px) {
  .alert-save-drug-label {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 638px;
  }
}
