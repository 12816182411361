// Margins
.m {
    &l {
        &-auto {
            margin-left: auto !important;
        }
    }

    &r {
        &-auto {
            margin-right: auto !important;
        }
    }

    &x {
        &-auto {
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

    &y {
        &-auto {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }
}

@for $i from 0 through 10 {
    .m {
        &-#{$i} {
            margin: calc(4px * #{$i}) !important;
        }

        &x {
            &-#{$i} {
                margin-left: calc(4px * #{$i}) !important;
                margin-right: calc(4px * #{$i}) !important;
            }
        }

        &y {
            &-#{$i} {
                margin-top: calc(4px * #{$i}) !important;
                margin-bottom: calc(4px * #{$i}) !important;
            }
        }

        &t {
            &-#{$i} {
                margin-top: calc(4px * #{$i}) !important;
            }
        }

        &l {
            &-#{$i} {
                margin-left: calc(4px * #{$i}) !important;
            }
        }

        &r {
            &-#{$i} {
                margin-right: calc(4px * #{$i}) !important;
            }
        }

        &b {
            &-#{$i} {
                margin-bottom: calc(4px * #{$i}) !important;
            }
        }
    }
}

// Paddings
@for $i from 0 through 10 {
    .p {
        &-#{$i} {
            padding: calc(4px * #{$i}) !important;
        }

        &x {
            &-#{$i} {
                padding-left: calc(4px * #{$i}) !important;
                padding-right: calc(4px * #{$i}) !important;
            }
        }

        &y {
            &-#{$i} {
                padding-top: calc(4px * #{$i}) !important;
                padding-bottom: calc(4px * #{$i}) !important;
            }
        }

        &t {
            &-#{$i} {
                padding-top: calc(4px * #{$i}) !important;
            }
        }

        &l {
            &-#{$i} {
                padding-left: calc(4px * #{$i}) !important;
            }
        }

        &r {
            &-#{$i} {
                padding-right: calc(4px * #{$i}) !important;
            }
        }

        &b {
            &-#{$i} {
                padding-bottom: calc(4px * #{$i}) !important;
            }
        }
    }
}