$primary: #007BFF;
$info: #55C2EB;
$success: #5CB85C;
$danger: #DC3545;
$white: #fff;
$black: #000;
$disabled: #E4E4E4;

.create-drug-label {
    &__grid {
        display: grid;
        grid-template-columns: 556px 1fr;
        grid-column-gap: 22px;

        .form-control,
        .btn {
            font-size: 16px;
            font-weight: 400;
        }
    }

    &__actions {
        grid-column: span 2;
        margin-top: 50px;
        text-align: right;

        .btn+.btn {
            margin-left: 13px;
        }
    }
}

.printing {
    &__body {
        border: 2px solid $primary;
        border-radius: 5px;
        padding: 5px 10px;
    }

    &__table {
        width: 100%;

        tr {

            th,
            td {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        thead {
            border-bottom: 1px solid #000;

            tr:last-child th {
                padding-bottom: 10px;
            }
        }

        tbody {
            border-bottom-color: transparent;
            border-bottom-width: 0;

            tr {
                &:first-child td {
                    padding-top: 10px;
                }

                &:last-child td {
                    border-bottom-color: transparent;
                    border-bottom-width: 0;
                }
            }
        }
    }
}

.panel-create-drug-label {
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;

    .panel {
        &-heading {
            background-color: $primary;
            padding: 15px 20px;

            h4 {
                font-size: 18px;
            }
        }

        &-body {
            padding: 32px 20px 28px;
        }

        &-grid {
            display: grid;
            grid-template-columns: 1fr;
        }

        &-form {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 22px;
            font-size: 16px;

            dt {
                font-weight: 400;
                padding-top: 8px;
            }

            .form-control {
                font-size: 16px;
                &[disabled]{
                    pointer-events: none !important;
                    background-color: #eeeeee !important;
                }
            }

            &.is-hidden {
                visibility: hidden;
                opacity: 0;
            }

            .is-disabled {
                pointer-events: none !important;
                opacity: .5 !important;
            }
        }

        &-table {
            border: 2px solid $primary;
            border-radius: 6px;
            padding: 10px 20px 20px;

            table {
                border-width: 0;

                tr {

                    th,
                    td {
                        font-weight: 400;
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }

                    &:last-child {
                        td {
                            border-bottom-width: 0;
                        }
                    }
                }

                tbody {
                    border-bottom-width: 0;
                    font-size: 20px;
                }
            }

            input,
            .form-control {
                font-size: 14px;
            }
            input[type="checkbox"]{
                pointer-events: none;
            }
        }
    }

    .text-primary,
    a {
        color: $primary;
    }

    select.form-control {
        padding-left: 11px;
        appearance: none;
        position: relative;
        background: $white url('../../images/select-arrow.png') no-repeat right 6px center/auto;
    }

    label {
        font-weight: 400;
    }

    .btn {
        &-primary {
            background-color: $primary;

            &:hover,
            &:focus {
                background-color: darken($primary, 10%);
            }
        }

        &-info {
            background-color: $info;

            &:hover,
            &:focus {
                background-color: darken($info, 10%);
            }
        }

        &-success {
            background-color: $success;

            &:hover,
            &:focus {
                background-color: darken($success, 10%);
            }
        }

        &-danger {
            background-color: $danger;

            &:hover,
            &:focus {
                background-color: darken($danger, 10%);
            }
        }

        &[disabled] {
            background-color: $disabled;
            border-color: $disabled;
            opacity: 1;
        }
    }

    @media (min-width: 1200px) {
        .panel {
            &-grid {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-column-gap: 50px;
            }

            &-table {
                display: inline-block;
            }
        }
    }
}

.modal-edit-branch {
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: normal;


    .form {
        &-group {
            margin-bottom: 8px;
        }

        &-control {
            padding-left: 12px;
            padding-right: 12px;
            font-size: 16px;
        }
    }

    label {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 16px;
    }

    select.form-control {
        padding-left: 11px;
        appearance: none;
        position: relative;
        background: $white url('../../images/select-arrow.png') no-repeat right 6px center/auto;
    }

    textarea.no-resize {
        resize: none;
    }

    .modal {
        &-dialog {
            width: 617px;
        }

        &-header {
            margin: 0 10px;
        }

        &-title {
            font-size: 16px;
            padding: 10px 0 15px;
            color: $black;
        }

        &-body {
            padding: 12px 10px 10px;
        }

        &-footer {
            border-top: none;
            padding: 22px 10px;
        }
    }
}

.alert-save-drug-label {
    position: fixed;
    top: 20px;
    left: 15px;
    right: 15px;
    padding: 18px 14px;
    font: 300 16px/24px 'Kanit';
    line-height: 1.5;
    z-index: 9999;
}

@media (min-width: 992px) {
    .alert-save-drug-label {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        width: 638px;
    }
}