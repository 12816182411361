.btn-inline {
	.btn {
		margin: 0 0 0 5px;
	}
}

.btn {
	font-size: 17px;
	font-weight: lighter;

}

.btn-default:hover {
	background-color: #35baf3;
	color: #fff;
}

.btn-footer-group .col-sm-6>.btn {
	padding: 4px 60px;
}

.btn-outline {
	&-warning {
		background-color: transparent;
		color: #eea236;
		border-color: #eea236;

		&:hover {
			background-color: #eea236;
			color: #fff;
		}
	}

	&-danger {
		background-color: transparent;
		color: #d43f3a;
		border-color: #d43f3a;

		&:hover {
			background-color: #d43f3a;
			color: #fff;
		}
	}
}