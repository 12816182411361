.table-hover > tbody > tr:hover td {
	background-color:#ededed;
}

.table > thead > tr > th {
  border-bottom: 2px solid #4098e4;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.branch .table > thead > tr > th {
  border-bottom: 2px solid #01A9A9;
}

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: inherit;
  }
}

.table {
  > tbody > tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }
}

.modal-body .table {
  margin: 0;
  width: 100% !important;
}

.table tbody tr.success td {
  border-top: 1px solid #d6e9c6;
  border-bottom: 1px solid #d6e9c6;
}

.table tbody tr.info td {
  border-top: 1px solid #bce8f1;
  border-bottom: 1px solid #bce8f1;
}

.table tbody tr.warning td {
  border-top: 1px solid #faebcc;
  border-bottom: 1px solid #faebcc;
}

.table tbody tr.danger td {
  border-top: 1px solid #ebccd1;
  border-bottom: 1px solid #ebccd1;
}
